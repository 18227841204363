<template>
  <div style="min-width: 70px; text-align: center">
    <i v-if="p_showEdit" @click="m_onEdit" style="margin-right: 10px" class="la la-edit"></i>
    <i v-if="p_showCopy" @click="m_onCopy" class="la la-copy" style="margin-right: 10px"></i>
    <i v-if="p_showDelete" @click.stop.prevent="m_onDelete" class="la la-trash"></i>
  </div>
</template>

<script>
export default {
  name: "cmp-actions",
  props: {
    p_showEdit: {
      default: true,
      type: Boolean,
    },
    p_showCopy: {
      default: true,
      type: Boolean,
    },
    p_showDelete: {
      default: true,
      type: Boolean,
    },
  },
  methods: {
    m_onEdit() {
      this.$emit("e_edit");
    },
    m_onCopy() {
      this.$emit("e_copy");
    },
    m_onDelete() {
      this.$emit("e_delete");
    },
  },
};
</script>

<style></style>
