// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_ERROR = "setError";

const state = {
  user: null,
  error: null,
  st_admin: null,
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.user != null;
  },
  gt_isAdmin(state) {
    return state.st_admin;
  },
};

const actions = {
  [LOGIN]({ commit }, credentials) {
    commit(SET_AUTH, credentials);
  },
  [VERIFY_AUTH]({ commit, getters }) {
    if (getters.currentUser) {
      commit(SET_ERROR, null);
    } else {
      commit(SET_AUTH, null);
      commit(SET_ERROR, true);
    }
  },
  [LOGOUT]({ commit }) {
    commit(PURGE_AUTH);
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, authUser) {
    if (authUser == null) {
      state.user = null;
      return;
    }
    const { uid, email, emailVerified, displayName } = authUser;
    state.user = { uid, email, emailVerified, displayName };
  },
  [PURGE_AUTH](state) {
    state.user = null;
    state.errors = null;
  },
  mt_setAdmin(state, payload) {
    state.st_admin = payload;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
