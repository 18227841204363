<template>
  <div class="d-flex flex-column-fluid">
    <!--begin::Container-->
    <div class="container">
      <!--begin::Card-->
      <div class="card card-custom card-transparent">
        <div class="card-body p-0">
          <!--begin::Wizard-->
          <div
            class="wizard wizard-4"
            id="kt_wizard"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
          >
            <!--begin::Wizard Nav-->
            <div class="wizard-nav">
              <div class="wizard-steps">
                <div
                  class="wizard-step"
                  data-wizard-type="step"
                  data-wizard-state="current"
                >
                  <div class="wizard-wrapper">
                    <div class="wizard-number">
                      <i
                        style="color: white"
                        class="menu-icon flaticon2-open-text-book"
                      ></i>
                    </div>
                    <div class="wizard-label">
                      <div class="wizard-title text-uppercase">LISTING</div>
                      <div class="wizard-desc">Subject</div>
                    </div>
                  </div>
                </div>
                <!--begin::Wizard Nav-->
                <div class="wizard-step" data-wizard-type="step">
                  <div class="wizard-wrapper">
                    <div class="wizard-label">
                      <b-button
                        @click="onPublishClicked()"
                        variant="primary"
                        class="btn font-weight-bolder px-9 py-4"
                      >
                        <i
                          style="color: #fff"
                          class="menu-icon flaticon2-send-1"
                        ></i
                        >Publish</b-button
                      >
                    </div>
                  </div>
                </div>
                <!--end::Wizard Nav-->
              </div>
            </div>
            <!--end::Wizard Nav-->
            <!--begin::Card-->
            <div
              class="card card-custom card-shadowless rounded-top-0 text-left"
              style="min-height: 72vh"
            >
              <!--begin::Body-->

              <div class="card-body p-0">
                <div class="row py-8 px-8 py-lg-15 px-lg-10">
                  <div class="col-lg-6 col-xl-6">
                    <div class="col-6" style="float: left">
                      <span class="form-text text-muted text-left"
                        >Select Education level:</span
                      >
                      <b-form-select
                        class="form-control form-control-solid form-control-lg"
                        v-model="selectedSubjectEducation"
                        :options="education"
                        @change="onEducationSelected"
                        :disabled="education.length == 0"
                      >
                      </b-form-select>
                    </div>
                    <div
                      class="col-6"
                      style="float: left"
                      v-if="selectedSubjectEducation != 'a'"
                    >
                      <span class="form-text text-muted text-center"
                        >Select Category:</span
                      >
                      <b-form-select
                        class="form-control form-control-solid form-control-lg"
                        v-model="selectedSubjectCategory"
                        :options="categories"
                        @change="onCategorySelected"
                        :disabled="
                          selectedSubjectEducation == null ||
                          selectedSubjectEducation == '' ||
                          categories.length == 0
                        "
                      >
                      </b-form-select>
                    </div>
                  </div>
                  <v-data-table
                    style="width: 100%; min-height: 100%"
                    class="font-weight-bolder"
                    :headers="headers"
                    :items="subjects"
                    disable-pagination
                    :hide-default-footer="true"
                    :search="search"
                    :custom-filter="filterText"
                    :loading="tableLoading"
                    loading-text="Please wait"
                    dense
                  >
                    <template v-slot:top>
                      <v-text-field
                        v-model="search"
                        label="Search"
                        class="mx-4"
                      ></v-text-field>
                    </template>
                    <template v-slot:[`item.education`]="{ item }">
                      {{ getEducationName(item.education) }}
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                      <cmp-actions
                        :p_item="item"
                        @e_edit="editItem(item)"
                        @e_copy="copyItem(item)"
                        @e_delete="deleteItem(item.key)"
                      />
                    </template>
                  </v-data-table>
                </div>
              </div>
              <!--end::Body-->
            </div>
            <!--end::Card-->
          </div>
          <!--end::Wizard-->
        </div>
      </div>
      <!--end::Card-->
    </div>
    <!--end::Container-->
  </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapState } from "vuex";
import {
  subjectsCollection,
  applicationsCollection,
  dataCollection,
} from "@/core/services/firebase.js";
import { copyClipboard } from "@/core/utils/copyclipboard.js";
import c_actions from "../../components/cmp-actions.vue";
import { l_publishAll } from "../../../core/utils/lib-publish.js";
import { firebase } from "@firebase/app";

import {
  studentSessionsCollection,
  tutorsCollection,
  tutorSubjectCollection,
} from "../../../core/services/firebase";
export default {
  name: "subject-lst",
  components: { "cmp-actions": c_actions },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Subjects" }]);
    this.selectedEducation = this.$store.getters[
      "fire/getSelectedSubjectEducation"
    ];
    this.selectedCategory = this.$store.getters[
      "fire/getSelectedSubjectCategory"
    ];
    this.$store.commit("fire/setSelectedSubjectEducation", "a");
    this.categories = this.$store.getters["fire/getCategoriesForSubject"](
      "all"
    );
  },
  data() {
    return {
      categories: [],
      selectedEducation: "",
      selectedCategory: "",
      tableLoading: false,
      search: "",
      headers: [
        {
          text: "Key",
          value: "id",
        },
        {
          text: "EN",
          value: "en",
        },
        // {
        //   text: "AR",
        //   value: "ar",
        // },
        {
          text: "Education level",
          value: "education",
        },
        {
          text: "Category",
          value: "category",
        },
        {
          text: "Actions",
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    ...mapState("fire", ["education", "applications"]),
    selectedSubjectEducation: {
      get() {
        return this.$store.getters["fire/getSelectedSubjectEducation"];
      },
      set(value) {
        this.$store.commit("fire/setSelectedSubjectEducation", value);
      },
    },
    selectedSubjectCategory: {
      get() {
        return this.$store.getters["fire/getSelectedSubjectCategory"];
      },
      set(value) {
        this.$store.commit("fire/setSelectedSubjectCategory", value);
      },
    },
    subjects() {
      return this.$store.getters["fire/getSubjects"](
        this.selectedSubjectCategory,
        false
      );
    },
  },
  methods: {
    async onPublishClicked() {
      await l_publishAll(this);
    },
    getEducationName(edu) {
      if (edu == "a") {
        return "all";
      } else if (edu == "s") {
        return "school";
      } else if (edu == "u") {
        return "university";
      } else if (edu == "l") {
        return "languages";
      } else if (edu == "o") {
        return "others";
      }
    },
    onEducationSelected(value) {
      this.$store.commit("fire/setSelectedSubjectEducation", value);
      this.categories = this.$store.getters["fire/getCategoriesForSubject"](
        value
      );
    },
    onCategorySelected(value) {
      this.$store.commit("fire/setSelectedSubjectCategory", value);
    },
    editItem(item) {
      this.$router.push({
        name: "subjectEditForm",
        params: {
          routerKey: item.id,
          otherProp: {
            en: item.en,
            ar: item.ar,
            education: item.education,
            category: item.category,
          },
        },
      });
    },
    async deleteItem(key) {
      const result = await this.$swal({
        icon: "warning",
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn font-weight-bold btn-danger",
          cancelButton: "btn font-weight-bold btn-light",
        },
        showCancelButton: true,
        buttonsStyling: false,
      });
      if (result && result.value) {
        await dataCollection.doc('subjects').update({
            [key]: firebase.firestore.FieldValue.delete()
        });
        await subjectsCollection.doc(key).delete();
        await applicationsCollection
          .where("subject", "==", key)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              doc.ref.delete();
            });
          });
        firebase
          .firestore()
          .collectionGroup("subjects")
          .where("subject", "==", key)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              doc.ref.delete();
            });
          });

        firebase
          .firestore()
          .collectionGroup("sessions")
          .where("subject", "==", key)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              doc.ref.delete();
            });
          });

        this.$swal.fire({
          icon: "success",
          title: "Deleted!",
          text: "Subject has been deleted.",
          customClass: {
            confirmButton: "btn font-weight-bold btn-light",
            buttonsStyling: false,
          },
        });
      }
    },
    filterText(value, search, item) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1
      );
    },

    copyItem(item) {
      copyClipboard(this.$swal, `${item.id} ${item.en} ${item.ar}`);
    },
  },
};
</script>
