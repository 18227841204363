<template>
  <div class="d-flex flex-column-fluid">
    <!--begin::Container-->
    <div class="container">
      <!--begin::Card-->
      <div class="card card-custom card-transparent">
        <div class="card-body p-0">
          <!--begin::Wizard-->
          <div
            class="wizard wizard-4"
            id="kt_wizard"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
          >
            <!--begin::Wizard Nav-->
            <div class="wizard-nav">
              <div class="wizard-steps">
                <div
                  class="wizard-step"
                  data-wizard-type="step"
                  data-wizard-state="current"
                >
                  <div class="wizard-wrapper">
                    <div class="wizard-number">
                      <i
                        style="color: white"
                        class="menu-icon flaticon-users"
                      ></i>
                    </div>
                    <div class="wizard-label">
                      <div class="wizard-title text-uppercase">LISTING</div>
                      <div class="wizard-desc">Students</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--end::Wizard Nav-->
            <!--begin::Card-->
            <div
              class="card card-custom card-shadowless rounded-top-0 text-center"
              style="min-height: 72vh"
            >
              <!--begin::Body-->

              <div class="card-body p-0">
                <div class="row py-8 px-8 py-lg-15 px-lg-10">
                  <div class="col-lg-6 col-xl-6">
                    <div class="col-lg-6 col-xl-6">
                      <span class="form-text text-muted text-left"
                        >Select student's status:</span
                      >
                      <b-form-select
                        class="form-control form-control-solid form-control-lg"
                        v-model="selectedStatus"
                        :options="statuses"
                        @change="onStatusChanged"
                      >
                      </b-form-select>
                    </div>
                  </div>
                  <v-data-table
                    style="width: 100%; min-height: 100%"
                    class="font-weight-bolder"
                    :headers="headers"
                    :items="getStudentsByStatus"
                    disable-pagination
                    :hide-default-footer="true"
                    :search="search"
                    :custom-filter="filterText"
                    :loading="tableLoading"
                    loading-text="Please wait"
                    dense
                  >
                    <template v-slot:top>
                      <v-text-field
                        v-model="search"
                        label="Search"
                        class="mx-4"
                      ></v-text-field>
                    </template>

                    <template v-slot:[`item.status.account`]="{ item }">
                      <span
                        class="label label-lg font-weight-bold label-inline"
                        :class="
                          statuses[getStatusIndexByKey(item.status.account)]
                            .class
                        "
                        >{{
                          statuses[getStatusIndexByKey(item.status.account)]
                            .text
                        }}
                      </span>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                      <cmp-actions
                        :p_item="item"
                        @e_edit="editItem(item)"
                        @e_copy="copyItem(item)"
                        @e_delete="deleteItem(item.id)"
                        :p_showDelete="true"
                      />
                    </template>
                  </v-data-table>
                </div>
              </div>
              <!--end::Body-->
            </div>
            <!--end::Card-->
          </div>
          <!--end::Wizard-->
        </div>
      </div>
      <!--end::Card-->
    </div>
    <!--end::Container-->
  </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters, mapState } from "vuex";
import {
  studentsCollection,
  studentSessionsCollection,
  usersCollection,
  sessionsCollection,
} from "@/core/services/firebase.js";
import { copyClipboard } from "@/core/utils/copyclipboard.js";
import c_actions from "../../components/cmp-actions.vue";

export default {
  name: "students-lst",
  components: { "cmp-actions": c_actions },

  mounted() {
    this.selectedStatus = this.$store.getters["fire/getSelectedStudentStatus"];
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Students" }]);
    this.selectedStudents = this.$store.getters["fire/getSelectedStudent"];
  },
  data() {
    return {
      selectedStudent: "",
      selectedStatus: "",
      tableLoading: false,
      search: "",
      headers: [
        {
          text: "Display name",
          value: "account.displayName",
        },
        {
          text: "Email",
          value: "id",
        },
        {
          text: "Phone number",
          value: "account.phone",
        },
        {
          text: "Status",
          value: "status.account",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
        },
      ],
      statuses: [
        {
          value: "",
          text: "All",
          class: "",
        },
        {
          value: "A",
          text: "Active",
          class: "label-light-success",
        },
        {
          value: "B",
          text: "Banned",
          class: "label-light-danger",
        },
      ],
    };
  },
  computed: mapGetters("fire", ["getStudentsByStatus"]),
  methods: {
    getStatusIndexByKey(key) {
      for (let i = 0; i < this.statuses.length; i++) {
        if (this.statuses[i].value == key) return i;
      }
      return 0;
    },
    onStatusChanged(value) {
      this.$store.commit("fire/setSelectedStudentStatus", value);
    },
    onStudentSelected(value) {
      this.tableLoading = true;

      this.$store.commit("fire/setSelectedStudent", value);

      this.tableLoading = false;
    },
    editItem(item) {
      this.$store.commit("fire/setSelectedStudent", item);
      this.$router.push({
        name: "StudentEditForm",
        params: {
          routerKey: item.id,
        },
      });
    },
    async deleteItem(id) {
      const result = await this.$swal({
        icon: "warning",
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn font-weight-bold btn-danger",
          cancelButton: "btn font-weight-bold btn-light",
        },
        showCancelButton: true,
        buttonsStyling: false,
      });
      if (result && result.value) {
        await studentsCollection.doc(id).delete();
        await studentSessionsCollection.doc(id).delete();
        await usersCollection.doc(id).delete();
        await sessionsCollection
          .where("student", "==", id)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              // doc.data() is never undefined for query doc snapshots
              doc.delete();
            });
          })
          .catch((error) => {
            console.log("Error getting documents: ", error);
          });
        this.$swal.fire({
          icon: "success",
          title: "Deleted!",
          text: "Student has been deleted.",
          customClass: {
            confirmButton: "btn font-weight-bold btn-light",
            buttonsStyling: false,
          },
        });
      }
    },
    filterText(value, search, item) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1
      );
    },

    copyItem(item) {
      copyClipboard(this.$swal, `${item.id} ${item.account.displayName}`);
    },
  },
};
</script>
