<template>
  <div class="d-flex flex-column-fluid">
    <!--begin::Container-->
    <div class="container">
      <!--begin::Card-->
      <div class="card card-custom card-transparent">
        <div class="card-body p-0">
          <!--begin::Wizard-->
          <div
            class="wizard wizard-4"
            id="kt_wizard"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
          >
            <!--begin::Wizard Nav-->
            <div class="wizard-nav">
              <div class="wizard-steps">
                <div
                  class="wizard-step"
                  data-wizard-type="step"
                  data-wizard-state="current"
                >
                  <div class="wizard-wrapper">
                    <div class="wizard-number">
                      <i
                        style="color: white"
                        class="menu-icon flaticon2-shopping-cart-1"
                      ></i>
                    </div>
                    <div class="wizard-label">
                      <div class="wizard-title text-uppercase">LISTING</div>
                      <div class="wizard-desc">Promo Code Users</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--end::Wizard Nav-->
            <!--begin::Card-->
            <div
              class="card card-custom card-shadowless rounded-top-0 text-center"
              style="min-height: 72vh"
            >
              <div class="card-body p-0">
                <div
                  class="row justify-content-center py-8 px-8 py-lg-15 px-lg-10"
                >
                  <v-data-table
                    style="width: 100%; min-height: 100%"
                    class="font-weight-bolder"
                    :headers="headers"
                    :items="promoCodes"
                    disable-pagination
                    :hide-default-footer="true"
                    :search="search"
                    dense
                  >
                    <template v-slot:top>
                      <v-text-field
                        v-model="search"
                        label="Search"
                        class="mx-4"
                      ></v-text-field>
                    </template>
                  </v-data-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { transactionsCollection } from "@/core/services/firebase.js";
import { mapState, mapGetters } from "vuex";
import moment from "moment";
export default {
  name: "promoCodes",
  data() {
    return {
      search: "",
      headers: [
        {
          text: "Student",
          value: "student",
        },
        {
          text: "Date of usage",
          value: "timestamp",
        },
        {
          text: "Promo code",
          value: "promocode",
        },
        {
          text: "Subtotal",
          value: "subtotal",
        },
        {
          text: "Discount",
          value: "discount",
        },
        {
          text: "total",
          value: "total",
        },
      ],
      promoCodes: [],
    };
  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Promo Codes Users" }]);
    this.getPromoCodes();
  },
  methods: {
    async getPromoCodes() {
      let promoCodesDocs = transactionsCollection.where("status", "==", "PAID");
      promoCodesDocs = promoCodesDocs.where("discount", ">", 0);
      let promoCodes = await promoCodesDocs.get();
      promoCodes.forEach((doc) => {
        let { student, subtotal, timestamp, discount, promocode, amount } =
          doc.data();

        this.promoCodes.push({
          student,
          subtotal,
          total: amount,
          timestamp: this.getLocalDate(timestamp),
          discount: discount + "%",
          promocode,
        });
      });
    },
    getLocalDate(date) {
      return moment(date.toDate()).format("MMMM Do YYYY, h:mm:ss a");
    },
  },
};
</script>

<style>
.btn-success {
  color: #fff !important;
  background-color: #28a745 !important;
  border-color: #28a745 !important;
}
</style>