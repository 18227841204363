<template>
  <div class="d-flex flex-column-fluid">
    <!--begin::Container-->
    <div class="container">
      <!--begin::Card-->
      <div class="card card-custom card-transparent">
        <div class="card-body p-0">
          <!--begin::Wizard-->
          <div
            class="wizard wizard-4"
            id="kt_wizard"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
          >
            <!--begin::Wizard Nav-->
            <div class="wizard-nav">
              <div class="wizard-steps">
                <div
                  class="wizard-step"
                  data-wizard-type="step"
                  data-wizard-state="current"
                >
                  <div class="wizard-wrapper">
                    <div class="wizard-number">
                      <i
                        style="color: white"
                        class="menu-icon flaticon-piggy-bank"
                      ></i>
                    </div>
                    <div class="wizard-label">
                      <div class="wizard-title text-uppercase">LISTING</div>
                      <div class="wizard-desc">Bank Transfers</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--end::Wizard Nav-->
            <!--begin::Card-->
            <div
              class="card card-custom card-shadowless rounded-top-0 text-center"
              style="min-height: 72vh"
            >
              <div class="card-body p-0">
                <div
                  class="row justify-content-center py-8 px-8 py-lg-15 px-lg-10"
                >
                  <v-data-table
                    style="width: 100%; min-height: 100%"
                    class="font-weight-bolder"
                    :headers="headers"
                    :items="bankTransfers"
                    disable-pagination
                    :hide-default-footer="true"
                    :search="search"
                    :custom-filter="filterText"
                    dense
                    @click:row="showItems"
                  >
                    <template v-slot:top>
                      <v-text-field
                        v-model="search"
                        label="Search"
                        class="mx-4"
                      ></v-text-field>
                    </template>
                    <template v-slot:[`item.date`]="{ item }">
                      {{ getLocalDate(item.date) }}
                    </template>
                  </v-data-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  bankTransferCollection,
  bankTransferApproved,
  bankTransferDenied,
} from "@/core/services/firebase.js";
import c_actions from "../../components/cmp-actionsBankTransfer.vue";
import { mapState, mapGetters } from "vuex";
import moment from "moment";
export default {
  name: "bankTransfers",
  components: { "cmp-actionsBankTransfer": c_actions },
  computed: {
    ...mapState("fire", ["bankTransfers"]),
    ...mapGetters("fire", ["getSubjectNameByID"]),
  },
  data() {
    return {
      search: "",
      headers: [
        {
          text: "Student",
          value: "student",
        },
        {
          text: "Date of request",
          value: "date",
        },
        {
          text: "Subtotal",
          value: "subtotal",
        },
        {
          text: "Promocode",
          value: "promocode",
        },
        {
          text: "Promocode discount",
          value: "discount",
        },
        {
          text: "Status",
          value: "status",
        },
        
      ],
    };
  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Bank Transfers" }]);
  },
  methods: {
    getLocalDate(date) {
      return moment(date.toDate()).format("MMMM Do YYYY, h:mm:ss a");
    },
    filterText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1
      );
    },
    async approveItem(item, subjects) {
      const subjs = subjects.map((a) => a.subject).join("<p></p>");
      let res = await bankTransferApproved({
        transactionId: item.banktransferId,
        email: item.student,
        subjects: subjs,
        discount: item.discount != null && item.discount > 0 ? item.discount : 0
      });
      return res.data.success;
    },
    async denyItem(item, subjects) {
      const subjs = subjects.map((a) => a.subject).join("<p></p>");
      let res = await bankTransferDenied({
        transactionId: item.banktransferId,
        email: item.student,
        subjects: subjs,
      });
      return res.data.success;
    },
    showItems(item) {
      let l_subjects = this.m_getSubjects(item);
      let l_text = this.m_concatSubjects(l_subjects);
      if (item && item.status != "PENDING")
        this.m_showReadOnlyDialog(item, l_text);
      else this.m_showDialog(item, l_text, l_subjects);
    },

    m_concatSubjects(p_subjects) {
      let l_text = "";
      p_subjects.forEach((p_element) => {
        l_text += p_element.subject + "<br>";
      });

      return l_text;
    },
    m_getSubjects(item) {
      let l_ret = [];
      item.products.forEach((p_element) => {
        if (p_element.type == "p") {
          l_ret.push({
            subject: p_element.quantity + " x Package of " + p_element.nrOfSessions,
          });
        } else {
          let subjectName = this.getSubjectNameByID(p_element.subject);
          let l_temp = {
            subject:
              p_element.quantity +
              " x " +
              subjectName[0].en +
              " with " +
              p_element.tutor,
          };
          l_ret.push(l_temp);
        }
      });
      return l_ret;
    },
    async m_showDialog(item, p_msg, subjects) {
      await this.$swal({
        icon: "info",
        title: "Subjets/Package",
        html: p_msg,
        confirmButtonText: "Approve",
        customClass: {
          confirmButton: "btn font-weight-bold btn-success",
          denyButton: "btn font-weight-bold btn-danger",
        },
        showDenyButton: true,
        denyButtonText: "Deny",
        reverseButtons: true,
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const res = await this.approveItem(item, subjects);
          if (res)
            this.$swal.fire("Changes applied!", "", "success");
          else this.$swal.fire("Changes are not applied", "", "warning");
        } else if (result.isDenied) {
          const res = await this.denyItem(item, subjects);
          if (res)
            this.$swal.fire("Changes applied!", "", "success");
          else this.$swal.fire("Changes are not applied", "", "warning");
        }
      });
    },
    async m_showReadOnlyDialog(item, p_msg) {
      await this.$swal({
        icon: "info",
        title: "Subjets/Package",
        html: p_msg,
        showDenyButton: false,
        showConfirmButton: false,
        reverseButtons: true,
        buttonsStyling: false,
      });
    },
  },
};
</script>

<style>
.btn-success {
  color: #fff !important;
  background-color: #28a745 !important;
  border-color: #28a745 !important;
}
</style>