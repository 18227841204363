export function calculateAge(moment, dobDate) {
  try {
    let age = moment(dobDate, "DD-MM-YYYY").fromNow().split(" ")[0];
    age = parseInt(age);
    if (age <= 0 || age > 100) return null;
    return age;
  } catch (p_error) {
    return null;
  }
}
export function calculateDateTimeAgo(moment, lastSeenDate) {
  return moment(lastSeenDate).fromNow();
}
