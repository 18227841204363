<template>
  <div class="d-flex flex-column-fluid">
    <!--begin::Container-->
    <div class="container">
      <!--begin::Card-->
      <div class="card card-custom card-transparent">
        <div class="card-body p-0">
          <!--begin::Wizard-->
          <div
            class="wizard wizard-4"
            id="kt_wizard"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
          >
            <!--begin::Wizard Nav-->
            <div class="wizard-nav">
              <div class="wizard-steps">
                <div
                  class="wizard-step"
                  data-wizard-type="step"
                  data-wizard-state="current"
                >
                  <div class="wizard-wrapper">
                    <div class="wizard-number">
                      <i
                        style="color: #fff"
                        class="menu-icon flaticon2-shopping-cart-1"
                      ></i>
                    </div>
                    <div class="wizard-label">
                      <div class="wizard-title text-uppercase">
                        {{ isEdit ? "EDIT" : "ADD" }}
                      </div>
                      <div class="wizard-desc">Promocode</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--end::Wizard Nav-->
            <!--begin::Card-->
            <div class="card card-custom card-shadowless rounded-top-0">
              <!--begin::Body-->
              <div class="card-body p-0">
                <div
                  class="row justify-content-center py-8 px-8 py-lg-15 px-lg-10"
                >
                  <div class="col-xl-12 col-xxl-10">
                    <!--begin::Wizard Form-->
                    <form class="form" id="kt_form">
                      <div class="row justify-content-center">
                        <div class="col-xl-9">
                          <!--begin::Wizard Step 1-->
                          <div
                            class="my-5 step"
                            data-wizard-type="step-content"
                            data-wizard-state="current"
                          >
                            <!--begin::Group-->
                            <div class="form-group row">
                              <label class="col-xl-3 col-lg-3 col-form-label"
                                >Name</label
                              >
                              <div class="col-lg-9 col-xl-9">
                                <b-form-input
                                  :disabled="isEdit"
                                  :state="nameState"
                                  v-model="name"
                                  class="
                                    form-control
                                    form-control-solid
                                    form-control-lg
                                  "
                                ></b-form-input>
                                <span class="form-text text-muted">Name</span>
                              </div>
                            </div>
                            <!--end::Group-->
                            <!--begin::Group-->
                            <div class="form-group row">
                              <label class="col-xl-3 col-lg-3 col-form-label"
                                >Promocode</label
                              >
                              <div class="col-lg-9 col-xl-9">
                                <b-form-input
                                  v-model="promocode"
                                  :state="promocodeState"
                                  class="
                                    form-control
                                    form-control-solid
                                    form-control-lg
                                  "
                                ></b-form-input>
                                <span class="form-text text-muted"
                                  >Promocode</span
                                >
                              </div>
                            </div>
                            <!--end::Group-->
                            <!--begin::Group-->
                            <div class="form-group row">
                              <label class="col-xl-3 col-lg-3 col-form-label"
                                >Description</label
                              >
                              <div class="col-lg-9 col-xl-9">
                                <b-form-input
                                  v-model="description"
                                  class="
                                    form-control
                                    form-control-solid
                                    form-control-lg
                                  "
                                ></b-form-input>
                                <span class="form-text text-muted"
                                  >Description</span
                                >
                              </div>
                            </div>
                            <!--end::Group-->
                            <!--begin::Group-->
                            <div class="form-group row">
                              <label class="col-xl-3 col-lg-3 col-form-label"
                                >Discount(%)</label
                              >
                              <div class="col-lg-9 col-xl-9">
                                <b-form-input
                                  type="number"
                                  v-model="discount"
                                  :state="discountState"
                                  class="
                                    form-control
                                    form-control-solid
                                    form-control-lg
                                  "
                                ></b-form-input>
                                <span class="form-text text-muted"
                                  >Discount</span
                                >
                              </div>
                            </div>
                            <!--end::Group-->
                          </div>
                          <!--end::Wizard Step 1-->

                          <!--begin::Wizard Actions-->
                          <div
                            class="
                              d-flex
                              justify-content-between
                              border-top
                              pt-10
                              mt-15
                            "
                          >
                            <div class="mr-2">
                              <c-back-button
                                title="Cancel"
                                inputClass="btn font-weight-bolder px-9 py-4"
                              ></c-back-button>
                              <!-- <b-button
                                @click="onCancelClicked()"
                                class="btn font-weight-bolder px-9 py-4"
                                >Cancel</b-button
                              > -->
                            </div>
                            <div>
                              <b-button
                                @click="onSaveClicked()"
                                variant="primary"
                                class="btn font-weight-bolder px-9 py-4"
                                >Save</b-button
                              >
                            </div>
                          </div>
                          <!--end::Wizard Actions-->
                        </div>
                      </div>
                    </form>
                    <!--end::Wizard Form-->
                  </div>
                </div>
              </div>
              <!--end::Body-->
            </div>
            <!--end::Card-->
          </div>
          <!--end::Wizard-->
        </div>
      </div>
      <!--end::Card-->
    </div>
    <!--end::Container-->
  </div>
</template>
<script>
const arabic = /[\u0600-\u06FF]/;
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { curriculumsCollection } from "@/core/services/firebase.js";
import CBackButton from "@/view/components/CBackButton.vue";
import { promoCodesCollection } from "../../../core/services/firebase";
import { mapGetters } from "vuex";
export default {
  name: "curriculum-frm",
  components: {
    CBackButton,
  },
  props: {
    routerKey: {
      type: String,
      default: "",
    },
    otherProp: {
      type: Object,
      default: null,
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "PromoCode" }]);
    this.isEdit = this.routerKey.trim().length != 0;
    this.fillDataOnUpdate();
  },
  data() {
    return {
      isEdit: false,
      name: "",
      description: "",
      promocode: "",
      discount: 0,
    };
  },
  computed: {
    ...mapGetters("fire", ["getPromoCode"]),
    nameState() {
      return this.name.trim().length > 0 ? true : false;
    },
    promocodeState() {
      return this.promocode.trim().length > 0 ? true : false;
    },
    discountState() {
      return this.discount > 0 ? true : false;
    },
  },
  methods: {
    fillDataOnUpdate() {
      if (!this.isEdit) return;

      this.name = this.otherProp.name;
      this.description = this.otherProp.description;
      this.promocode = this.otherProp.promocode;
      this.discount = this.otherProp.discount;
    },
    async onSaveClicked() {
      if (!this.nameState || !this.promocodeState || !this.discountState)
        return;
      if (this.getPromoCode(this.promocode).length > 0) {
        await this.$swal({
          icon: "error",
          title: "Duplicate promocode",
          text: `Promocode already exists`,
          buttonsStyling: false,
          confirmButtonText: "Ok",
          customClass: {
            confirmButton: "btn font-weight-bold btn-light",
          },
        });
        return;
      }
      const promocode = this.promocode;
      const payload = {
        name: this.name,
        discount: this.discount,
        description: this.description,
        promocode,
        expired: false,
      };
      await promoCodesCollection.doc(promocode).set(payload);
      await this.$swal({
        icon: "success",
        title: this.actionText(),
        text: `Promocode has been ${this.actionText().toLowerCase()}`,
        buttonsStyling: false,
        confirmButtonText: "Ok",
        customClass: {
          confirmButton: "btn font-weight-bold btn-light",
        },
      });

      if (!this.isEdit) this.clear();
    },
    actionText() {
      return this.isEdit ? "Updated" : "Added";
    },
    clear() {
      this.name = "";
      this.discount = 0;
      this.description = "";
      this.promocode = "";
    },
  },
};
</script>