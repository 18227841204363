<template>
  <div class="d-flex flex-column-fluid">
    <!--begin::Container-->
    <div class="container">
      <!--begin::Card-->
      <div class="card card-custom card-transparent">
        <div class="card-body p-0">
          <!--begin::Wizard-->
          <div
            class="wizard wizard-4"
            id="kt_wizard"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
          >
            <!--begin::Wizard Nav-->
            <div class="wizard-nav">
              <div class="wizard-steps">
                <div
                  class="wizard-step"
                  data-wizard-type="step"
                  data-wizard-state="current"
                >
                  <div class="wizard-wrapper">
                    <div class="wizard-number">
                      <i
                        style="color: white"
                        class="menu-icon flaticon2-shopping-cart-1"
                      ></i>
                    </div>
                    <div class="wizard-label">
                      <div class="wizard-title text-uppercase">LISTING</div>
                      <div class="wizard-desc">Promo Codes</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--end::Wizard Nav-->
            <!--begin::Card-->
            <div
              class="card card-custom card-shadowless rounded-top-0 text-center"
              style="min-height: 72vh"
            >
              <div class="card-body p-0">
                <div class="py-8 px-8 py-lg-15 px-lg-10">
                  <div class="row justify-content-end">
                    <b-button
                      to="promoCodeFrm"
                      variant="primary"
                      class="btn font-weight-bolder px-9 py-4"
                    >
                      <i
                        style="color: #fff"
                        class="menu-icon flaticon2-add-1"
                      ></i
                      >Add</b-button
                    >
                  </div>
                  <div class="row justify-content-center">
                    <v-data-table
                      style="width: 100%; min-height: 100%"
                      class="font-weight-bolder"
                      :headers="headers"
                      :items="getPromoCodes"
                      disable-pagination
                      :hide-default-footer="true"
                      :search="search"
                      dense
                    >
                      <template v-slot:top>
                        <v-text-field
                          v-model="search"
                          label="Search"
                          class="mx-4"
                        ></v-text-field>
                      </template>
                      <template v-slot:[`item.discount`]="{ item }">
                        {{item.discount + "%"}}
                      </template>
                      <template v-slot:[`item.expired`]="{ item }">
                        <v-checkbox
                          :input-value="item.expired"
                          class="d-inline-flex"
                          @change="setExpired($event, item)"
                        ></v-checkbox>
                      </template>
                    </v-data-table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { transactionsCollection } from "@/core/services/firebase.js";
import { mapState, mapGetters } from "vuex";
import moment from "moment";
import { promoCodesCollection } from "../../../core/services/firebase";
export default {
  name: "promoCodes",
  data() {
    return {
      search: "",
      headers: [
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Promo code",
          value: "promocode",
        },
        {
          text: "Description",
          value: "description",
        },
        {
          text: "Discount",
          value: "discount",
        },
        {
          text: "Expired ?",
          value: "expired",
        },
      ],
      promoCodes: [],
    };
  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Promo Codes" }]);
  },
  computed: {
    ...mapGetters("fire", ["getPromoCodes"]),
  },
  methods: {
    async setExpired(val, item) {
      await promoCodesCollection.doc(item.promocode).update({ expired: val });
    },
    getLocalDate(date) {
      return moment(date.toDate()).format("MMMM Do YYYY, h:mm:ss a");
    },
  },
};
</script>

<style>
.btn-success {
  color: #fff !important;
  background-color: #28a745 !important;
  border-color: #28a745 !important;
}
</style>