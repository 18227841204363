<template>
  <div class="d-flex flex-column-fluid">
    <!--begin::Container-->
    <div class="container" v-if="getSelectedTutor && getSelectedTutor.account">
      <!--begin::Card-->
      <div class="card card-custom card-transparent">
        <div class="card-body p-0">
          <!--begin::Wizard-->
          <div
            class="wizard wizard-4"
            id="kt_wizard"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
          >
            <!--begin::Wizard Nav-->
            <div class="wizard-nav">
              <div class="wizard-steps">
                <div
                  class="wizard-step"
                  data-wizard-type="step"
                  data-wizard-state="current"
                >
                  <div class="wizard-wrapper">
                    <div class="wizard-number">
                      <i
                        style="color: #fff"
                        class="menu-icon flaticon-users"
                      ></i>
                    </div>
                    <div class="wizard-label">
                      <div class="wizard-title text-uppercase">EDIT</div>
                      <div class="wizard-desc">Tutor</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--end::Wizard Nav-->
            <!--begin::Card-->
            <div class="card card-custom card-shadowless rounded-top-0">
              <!--begin::Body-->
              <div class="card-body p-0">
                <div
                  class="row justify-content-center py-8 px-8 py-lg-15 px-lg-10"
                >
                  <div class="col-xl-12 col-xxl-10">
                    <!--begin::Wizard Form-->
                    <form class="form" id="kt_form">
                      <div class="row justify-content-center">
                        <div class="col-xl-9">
                          <!--begin::Wizard Step 1-->
                          <div
                            class="my-5 step"
                            data-wizard-type="step-content"
                            data-wizard-state="current"
                          >
                            <!--begin::Group-->
                            <div class="form-group row">
                              <div
                                class="col-sm-4 col-md-3 col-lg-3 col-xl-3 symbol symbol-100"
                              >
                                <img
                                  class="symbol-label"
                                  :src="avatar"
                                  alt=""
                                />
                              </div>
                              <div class="col-4 py-8">
                                <b-button
                                  v-if="d_cv && d_cv != ''"
                                  :href="d_cv"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  variant="primary"
                                  class="btn font-weight-bolder px-9 py-4"
                                  >Open CV</b-button
                                >
                                <b-button
                                  v-else
                                  disabled
                                  variant="danger"
                                  class="btn font-weight-bolder px-9 py-4"
                                  >CV Not Found</b-button
                                >
                              </div>
                            </div>
                            <!--end::Group-->
                            <!--begin::Group-->
                            <div class="form-group row">
                              <label class="col-xl-3 col-lg-3 col-form-label"
                                >Email</label
                              >
                              <div class="col-lg-9 col-xl-9">
                                <b-form-input
                                  disabled
                                  v-model="getSelectedTutor.account.email"
                                  class="form-control form-control-solid form-control-lg"
                                ></b-form-input>
                                <span class="form-text text-muted"
                                  >Tutor's email</span
                                >
                              </div>
                            </div>
                            <!--end::Group-->
                            <!--begin::Group-->
                            <div class="form-group row">
                              <label class="col-xl-3 col-lg-3 col-form-label"
                                >Display name</label
                              >
                              <div class="col-lg-9 col-xl-9">
                                <b-form-input
                                  disabled
                                  v-model="getSelectedTutor.account.displayName"
                                  class="form-control form-control-solid form-control-lg"
                                ></b-form-input>
                                <span class="form-text text-muted"
                                  >Display name</span
                                >
                              </div>
                            </div>
                            <!--end::Group-->
                            <!--begin::Group-->
                            <div class="form-group row">
                              <label class="col-xl-3 col-lg-3 col-form-label"
                                >Phone number</label
                              >
                              <div class="col-lg-9 col-xl-9">
                                <b-form-input
                                  disabled
                                  v-model="getSelectedTutor.account.phone"
                                  class="form-control form-control-solid form-control-lg"
                                ></b-form-input>
                                <span class="form-text text-muted"
                                  >Phone number</span
                                >
                              </div>
                            </div>
                            <!--end::Group-->
                            <!--begin::Group-->
                            <div class="form-group row">
                              <label class="col-xl-3 col-lg-3 col-form-label"
                                >Age</label
                              >
                              <div class="col-lg-9 col-xl-9">
                                <b-form-input
                                  disabled
                                  v-model="age"
                                  class="form-control form-control-solid form-control-lg"
                                ></b-form-input>
                                <span class="form-text text-muted">Age</span>
                              </div>
                            </div>
                            <!--end::Group-->
                            <!--begin::Group-->
                            <div class="form-group row">
                              <label class="col-xl-3 col-lg-3 col-form-label"
                                >Nationality</label
                              >
                              <div class="col-lg-5 col-xl-5">
                                <b-form-input
                                  disabled
                                  v-model="getSelectedTutor.account.nationality"
                                  class="form-control form-control-solid form-control-lg"
                                ></b-form-input>
                                <span class="form-text text-muted"
                                  >Nationality</span
                                >
                              </div>
                              <label class="col-xl-2 col-lg-2 col-form-label"
                                >Gender</label
                              >
                              <div class="col-lg-2 col-xl-2">
                                <b-form-input
                                  disabled
                                  v-model="getSelectedTutor.account.gender"
                                  class="form-control form-control-solid form-control-lg align-middle"
                                ></b-form-input>
                                <span class="form-text text-muted">Gender</span>
                              </div>
                            </div>
                            <!--end::Group-->
                            <!--begin::Group-->
                            <div class="form-group row">
                              <label class="col-xl-3 col-lg-3 col-form-label"
                                >Address</label
                              >
                              <div class="col-lg-9 col-xl-9">
                                <b-form-input
                                  disabled
                                  v-model="getSelectedTutor.account.address"
                                  class="form-control form-control-solid form-control-lg"
                                ></b-form-input>
                                <span class="form-text text-muted"
                                  >Address</span
                                >
                              </div>
                            </div>
                            <!--end::Group-->
                            <!--begin::Group-->
                            <div class="form-group row">
                              <label class="col-xl-3 col-lg-3 col-form-label"
                                >GeoLocation</label
                              >
                              <div class="col-lg-9 col-xl-9">
                                <b-form-input
                                  disabled
                                  v-model="getSelectedTutor.account.location"
                                  class="form-control form-control-solid form-control-lg"
                                ></b-form-input>
                                <span class="form-text text-muted"
                                  >GeoLocation</span
                                >
                              </div>
                            </div>
                            <!--end::Group-->
                            <!--begin::Group-->
                            <div class="form-group row">
                              <label class="col-xl-3 col-lg-3 col-form-label"
                                >Languages</label
                              >
                              <div class="col-lg-9 col-xl-9">
                                <b-form-input
                                  disabled
                                  v-model="getSelectedTutor.account.lang"
                                  class="form-control form-control-solid form-control-lg"
                                ></b-form-input>
                                <span class="form-text text-muted"
                                  >Languages</span
                                >
                              </div>
                            </div>
                            <!--end::Group-->
                            <!--begin::Group-->
                            <!-- <div class="form-group row">
                              <label class="col-xl-3 col-lg-3 col-form-label"
                                >Last seen</label
                              >
                              <div class="col-lg-9 col-xl-9">
                                <b-form-input
                                  disabled
                                  v-model="lastseen"
                                  class="form-control form-control-solid form-control-lg"
                                ></b-form-input>
                                <span class="form-text text-muted"
                                  >Last seen</span
                                >
                              </div>
                            </div> -->
                            <!--end::Group-->
                            <!--begin::Group-->
                            <div class="form-group row">
                              <label class="col-xl-3 col-lg-3 col-form-label"
                                >Registerion date</label
                              >
                              <div class="col-lg-9 col-xl-9">
                                <b-form-input
                                  disabled
                                  v-model="registered"
                                  class="form-control form-control-solid form-control-lg"
                                ></b-form-input>
                                <span class="form-text text-muted"
                                  >Registration date</span
                                >
                              </div>
                            </div>
                            <!--end::Group-->
                            <!--begin::Group-->
                            <div class="form-group row">
                              <label class="col-xl-3 col-lg-3 col-form-label"
                                >Bio</label
                              >
                              <div class="col-lg-9 col-xl-9">
                                <b-form-textarea
                                  disabled
                                  v-model="getSelectedTutor.account.bio"
                                  rows="4"
                                  no-resize
                                  class="form-control form-control-solid form-control-lg"
                                ></b-form-textarea>
                                <span class="form-text text-muted">Bio</span>
                              </div>
                            </div>
                            <!--end::Group-->
                            <!--begin::Group-->
                            <div class="form-group row">
                              <label
                                class="col-xl-3 col-lg-3 col-form-label text-warning"
                                >Status</label
                              >
                              <div class="col-lg-9 col-xl-9">
                                <b-form-select
                                  class="form-control form-control-solid form-control-lg"
                                  v-model="status"
                                  :options="statuses"
                                >
                                </b-form-select>
                                <span class="form-text text-muted">Status</span>
                              </div>
                            </div>
                            <!--end::Group-->
                          </div>
                          <!--end::Wizard Step 1-->

                          <!--begin::Wizard Actions-->
                          <div
                            class="d-flex justify-content-between border-top pt-10 mt-15"
                          >
                            <div class="mr-2">
                              <c-back-button
                                ref="back_button"
                                title="Cancel"
                                inputClass="btn font-weight-bolder px-9 py-4"
                              ></c-back-button>
                              <!-- <b-button
                                @click="onCancelClicked()"
                                class="btn font-weight-bolder px-9 py-4"
                                >Cancel</b-button
                              > -->
                            </div>
                            <div>
                              <b-button
                                @click="onSaveClicked()"
                                variant="primary"
                                class="btn font-weight-bolder px-9 py-4"
                                >Save</b-button
                              >
                            </div>
                          </div>
                          <!--end::Wizard Actions-->
                        </div>
                      </div>
                    </form>
                    <!--end::Wizard Form-->
                  </div>
                </div>
              </div>
              <!--end::Body-->
            </div>
            <!--end::Card-->
          </div>
          <!--end::Wizard-->
        </div>
      </div>
      <!--end::Card-->
    </div>
    <!--end::Container-->
  </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import { cvStorageRef, tutorsCollection } from "@/core/services/firebase.js";
import CBackButton from "@/view/components/CBackButton.vue";
import { calculateAge, calculateDateTimeAgo } from "@/core/utils/datetime";

export default {
  name: "tutors-edit-frm",
  components: {
    CBackButton,
  },
  props: {
    routerKey: {
      type: String,
      default: "",
    },
  },
  mounted() {
    if (this.mustResetPage()) {
      this.$router.push({ path: "/" });
      return;
    }
    this.status = this.getSelectedTutor.status.account;
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Tutors-Edit" }]);
    if (this.getSelectedTutor.account.dob)
      this.age = calculateAge(
        this.$moment,
        this.getSelectedTutor.account.dob.toDate()
      );
    const registered = this.getSelectedTutor.account.registered;
    if (registered) {
      this.registered = registered.toDate().toDateString();
    }
    if (this.getSelectedTutor.additional) {
      this.d_cv = this.getSelectedTutor.additional.cv;
    }

    // this.lastseen = calculateDateTimeAgo(
    //   this.$moment,
    //   this.getSelectedTutor.account.lastseen.toDate()
    // );
  },
  data() {
    return {
      age: "",
      // lastseen: "",
      registered: "",
      previewingCV: false,
      cvDownloadURL: "",
      status: "",
      d_cv: "",
      statuses: [
        {
          value: "A",
          text: "Active",
        },
        {
          value: "B",
          text: "Banned",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("fire", ["getSelectedTutor"]),
    avatar() {
      if (this.getSelectedTutor && this.getSelectedTutor.account) {
        if (this.getSelectedTutor.account.avatar)
          return this.getSelectedTutor.account.avatar;

        if (this.getSelectedTutor.account.gender == "F")
          return process.env.BASE_URL + "media/svg/avatars/003-girl-1.svg";
      }

      return process.env.BASE_URL + "media/svg/avatars/001-boy.svg";
    },
  },
  methods: {
    mustResetPage() {
      return !this.getSelectedTutor || !this.getSelectedTutor.status;
    },
    async onSaveClicked() {
      const key = this.getSelectedTutor.account.email;
      const payload = {
        status: { account: this.status },
      };
      await tutorsCollection.doc(key).set(payload, { merge: true });
      await this.$swal({
        icon: "success",
        title: "Updated",
        text: `Tutor ${key} has been updated`,
        buttonsStyling: false,
        confirmButtonText: "Ok",
        customClass: {
          confirmButton: "btn font-weight-bold btn-light",
        },
      });
      this.$refs.back_button.onBackButtonClicked();
    },
  },
};
</script>
