import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";
import VueSweetalert2 from "vue-sweetalert2";
import { auth } from "@/core/services/firebase";
import { SET_AUTH } from "./core/services/store/auth.module";
import moment from "moment";

Vue.use(VueSweetalert2);
Vue.component(VueSweetalert2);
Vue.prototype.$moment = moment;

Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/metronic";
import "@/core/plugins/treeselect";
import "@mdi/font/css/materialdesignicons.css";
import "sweetalert2/dist/sweetalert2.min.css";

router.beforeEach(async (to, from, next) => {
  const accessible = to.matched.some((x) => x.meta.accessible);
  // console.log(from.name, to.name);
  if (to.name == "login") {
    next();
    return;
  }
  if (!auth.currentUser) {
    next("/login");
    return;
  }
  // if (!accessible && !auth.currentUser) {
  //   next("/login");
  //   return;
  // }

  const l_token = await auth.currentUser.getIdTokenResult();
  if (!l_token.claims.admin) {
    if (from.name != "login") next("/login");
    return;
  }
  next();
  store.dispatch(RESET_LAYOUT_CONFIG);
  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

let app;

auth.onAuthStateChanged(async (p_user) => {
  if (p_user) {
    const l_token = await p_user.getIdTokenResult();
    if (!l_token.claims.admin) {
      store.commit("auth/" + SET_AUTH, null);
      store.commit("auth/mt_setAdmin", null);
    } else {
      store.commit("auth/" + SET_AUTH, p_user);
      store.commit("auth/mt_setAdmin", true);
    }
  } else {
    store.commit("auth/" + SET_AUTH, null);
    store.commit("auth/mt_setAdmin", null);
  }

  if (!app) {
    app = new Vue({
      VueSweetalert2,
      router,
      store,
      i18n,
      vuetify,
      render: (h) => h(App),
    }).$mount("#app");
  }
});
