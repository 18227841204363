<template>
  <div>
    <Chat
      :participants="participants"
      :myself="myself"
      :messages="messages"
      :colors="colors"
      :border-style="borderStyle"
      :submit-icon-size="submitIconSize"
      :load-more-messages="toLoad.length > 0 ? loadMoreMessages : null"
      :async-mode="asyncMode"
      :scroll-bottom="scrollBottom"
      :display-header="false"
      :send-images="false"
      :profile-picture-config="profilePictureConfig"
      :timestamp-config="timestampConfig"
      :link-options="linkOptions"
      @onClose="onClose"
    />
  </div>
</template>

<script>
import { Chat } from "vue-quick-chat";
import "vue-quick-chat/dist/vue-quick-chat.css";
import { mapGetters } from "vuex";
import { database } from "@/core/services/firebase.js";
let ref = null;
export default {
  name: "cmp-chat",
  components: {
    Chat,
  },
  props: {
    tutor: {
      type: String,
    },
    student: {
      type: String,
    },
  },
  data() {
    return {
      visible: true,
      participants: [
        {
          name: "",
          id: "",
          profilePicture: "",
        },
      ],
      myself: {
        name: "",
        id: "",
        profilePicture: "",
      },
      messages: [],
      placeholder: "send your message",
      colors: {
        header: {
          bg: "#83bf6f",
          text: "#fff",
        },
        message: {
          myself: {
            bg: "#fff",
            text: "#83bf6f",
          },
          others: {
            bg: "#83bf6f",
            text: "#fff",
          },
          messagesDisplay: {
            bg: "#f7f3f3",
          },
        },
        submitIcon: "#83bf6f",
        submitImageIcon: "#b91010",
      },
      borderStyle: {
        topLeft: "10px",
        topRight: "10px",
        bottomLeft: "10px",
        bottomRight: "10px",
      },
      hideCloseButton: false,
      submitIconSize: 25,
      closeButtonIconSize: "20px",
      asyncMode: false,
      toLoad: [],
      scrollBottom: {
        messageSent: true,
        messageReceived: true,
      },
      displayHeader: true,
      profilePictureConfig: {
        others: true,
        myself: true,
        styles: {
          width: "30px",
          height: "30px",
          borderRadius: "50%",
        },
      },
      timestampConfig: {
        format: "HH:mm",
        relative: false,
      },
      // there are other options, you can check them here
      // https://soapbox.github.io/linkifyjs/docs/options.html
      linkOptions: {
        myself: {
          className: "myLinkClass",
          events: {
            click: function (e) {
              alert("Link clicked!");
            },
            mouseover: function (e) {
              alert("Link hovered!");
            },
          },
          format: function (value, type) {
            if (type === "url" && value.length > 50) {
              value = value.slice(0, 50) + "…";
            }
            return value;
          },
        },
        others: {
          className: "othersLinkClass",
          events: {
            click: function (e) {
              alert("Link clicked!");
            },
            mouseover: function (e) {
              alert("Link hovered!");
            },
          },
          format: function (value, type) {
            if (type === "url" && value.length > 50) {
              value = value.slice(0, 50) + "…";
            }
            return value;
          },
        },
      },
    };
  },
  computed: mapGetters("fire", ["getTutorById", "getStudentById"]),
  async mounted() {
    let st = this.getStudentById(this.student);
    let tu = this.getTutorById(this.tutor);
    this.myself.name = st[0].account.displayName;
    this.myself.id = st[0].account.email;
    this.myself.profilePicture = st[0].account.avatar;
    this.participants[0].name = tu[0].account.displayName;
    this.participants[0].profilePicture = tu[0].account.avatar;
    this.participants[0].id = tu[0].account.email;
    this.getdata();
  },
  beforeDestroy() {
    if (!ref) return;
    ref.off();
  },
  methods: {
    onType: function (event) {
      //here you can set any behavior
    },
    loadMoreMessages(resolve) {
      setTimeout(() => {
        resolve(this.toLoad); //We end the loading state and add the messages
        //Make sure the loaded messages are also added to our local messages copy or they will be lost
        this.messages.unshift(...this.toLoad);
        this.toLoad = [];
      }, 1000);
    },
    onMessageSubmit: function (message) {
      /*
       * example simulating an upload callback.
       * It's important to notice that even when your message wasn't send
       * yet to the server you have to add the message into the array
       */
      // this.messages.push(message);
      database.ref("chat/" + this.getchatid()).push(JSON.stringify(message));
      /*
       * you can update message state after the server response
       */
      // timeout simulating the request
      setTimeout(() => {
        message.uploaded = true;
      }, 2000);
    },
    onClose() {
      this.visible = false;
    },
    getdata() {
      ref = database.ref("chat/" + this.getchatid());
      ref.on("child_added", (snapshot) => {
        this.messages.push(JSON.parse(snapshot.val()));
        this.$forceUpdate();
      });
    },
    getchatid() {
      let id = `${this.student}|${this.tutor}`;
      id = id.replaceAll(".", "-");
      return id;
    },
  },
};
</script>

<style>
.container-message-manager {
  display: none !important;
}
</style>
