import { firebase } from "@firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import "firebase/functions";
import "firebase/database";

const instance = "production";
// firebase init - add your own config here
const firebaseConfig = {
  development: {
    apiKey: "AIzaSyDeC8WkblqgUlmcFfsY939hwL5VaIOAWm4",
    authDomain: "turors--dev.firebaseapp.com",
    databaseURL: "https://turors--dev.firebaseio.com",
    projectId: "turors--dev",
    storageBucket: "turors--dev.appspot.com",
    messagingSenderId: "818001599200",
    appId: "1:818001599200:web:3204faf67b86e67e2d8e85",
    measurementId: "G-49DMTH51VM",
  },
  production: {
    apiKey: "AIzaSyD7DKHR4UDnDdUiywYXoohFXY4aTLm7rrA",
    authDomain: "tutors--prod.firebaseapp.com",
    databaseURL: "https://tutors--prod.firebaseio.com",
    projectId: "tutors--prod",
    storageBucket: "tutors--prod.appspot.com",
    messagingSenderId: "717396452332",
    appId: "1:717396452332:web:6498479c21c918de3916c9",
    measurementId: "G-ME7476H1XV",
  },
};
firebase.initializeApp(firebaseConfig[instance]);
const functions = firebase.functions();
const sendMail = functions.httpsCallable('sendMail');
const fb_setAdmin = functions.httpsCallable('setAdmin');
const bankTransferApproved = functions.httpsCallable("bankTransferApproved");
const bankTransferDenied = functions.httpsCallable("bankTransferDenied");

// functions.useEmulator("localhost", 5001);

// utils
const db = firebase.firestore();
const database = firebase.database();
const newBatch = () => {
  return db.batch();
};
const auth = firebase.auth();
const storage = firebase.storage().ref();
const cvStorageRef = storage.child("cv");
const fb_eventsStorageref = storage.child("events");

// collection references
const curriculumsCollection = db.collection("curriculums");
const fb_adminsCollection = db.collection("admins");
const tutorsCollection = db.collection("tutors");
const studentsCollection = db.collection("students");
const dataCollection = db.collection("data");
const sessionsCollection = db.collection("sessions");
const applicationsCollection = db.collection("applications");
const tutorSubjectCollection = db.collection("tutorsubjects");
const categoriesCollection = db.collection("categories");
const subjectsCollection = db.collection("subjects");
const studentSessionsCollection = db.collection("studentsessions");
const tutorSessionsCollection = db.collection("tutorsessions");
const eventsCollection = db.collection("events");
const bankTransferCollection = db.collection("banktransfer");
const usersCollection = db.collection("users");
const transactionsCollection = db.collection("transactions");
const promoCodesCollection = db.collection("promocodes");
const eventsRef = db.collection("events").doc("events");
const sessionSettings = db.collection("sessionSettings").doc('settings');
const packagesCollection = db.collection("packages");

//auth providers
var googleAuthProvider = new firebase.auth.GoogleAuthProvider();

// export utils/refs
export {
  db,
  database,
  newBatch,
  auth,
  sendMail,
  fb_setAdmin,
  cvStorageRef,
  fb_eventsStorageref,
  googleAuthProvider,
  curriculumsCollection,
  fb_adminsCollection,
  tutorsCollection,
  studentsCollection,
  transactionsCollection,
  dataCollection,
  promoCodesCollection,
  sessionsCollection,
  applicationsCollection,
  tutorSubjectCollection,
  categoriesCollection,
  subjectsCollection,
  studentSessionsCollection,
  tutorSessionsCollection,
  eventsCollection,
  bankTransferCollection,
  usersCollection,
  bankTransferApproved,
  bankTransferDenied,
  eventsRef,
  sessionSettings,
  packagesCollection,
};
