<template>
  <!-- begin:: Footer -->
  <div class="footer py-4 d-flex flex-lg-column" id="kt_footer">
    <!--begin::Container-->
    <div
      class="d-flex flex-column flex-md-row align-items-center justify-content-between"
      v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }"
    >
      <!--begin::Copyright-->
      <div class="text-dark order-2 order-md-1">
        <span class="text-muted font-weight-bold mr-2">2020©</span>
        <a
          href="https://971tutors.com"
          target="_blank"
          class="text-dark-75 text-hover-primary"
          >971 Tutors</a
        >
      </div>
      <!--end::Copyright-->
      <!--begin::Nav-->
      <div class="nav nav-dark order-1 order-md-2 cursor-pointer">
        <span
          class="nav-link pr-3 pl-0"
          v-b-tooltip.hover.top="'support@971tutors.com'"
          >Team</span
        >
        <span
          class="nav-link px-3 cursor-pointer"
          v-b-tooltip.hover.top="'971+ 000 000'"
          >Contact</span
        >
        <span
          class="nav-link pl-3 pr-0 cursor-pointer"
          v-b-tooltip.hover.top="'0.0.2'"
          >Version</span
        >
      </div>
      <!--end::Nav-->
    </div>
    <!--end::Container-->
  </div>
  <!-- end:: Footer -->
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "KTFooter",
  computed: {
    ...mapGetters(["layoutConfig"]),

    /**
     * Check if footer container is fluid
     */
    widthFluid() {
      return this.layoutConfig("footer.width") === "fluid";
    },
  },
};
</script>
