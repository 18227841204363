<template>
  <div class="d-flex flex-column-fluid">
    <!--begin::Container-->
    <div class="container">
      <!--begin::Card-->
      <div class="card card-custom card-transparent">
        <div class="card-body p-0">
          <!--begin::Wizard-->
          <div
            class="wizard wizard-4"
            id="kt_wizard"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
          >
            <!--begin::Wizard Nav-->
            <div class="wizard-nav">
              <div class="wizard-steps">
                <div
                  class="wizard-step"
                  data-wizard-type="step"
                  data-wizard-state="current"
                >
                  <div class="wizard-wrapper">
                    <div class="wizard-number">
                      <i
                        style="color: #fff"
                        class="menu-icon flaticon2-files-and-folders"
                      ></i>
                    </div>
                    <div class="wizard-label">
                      <div class="wizard-title text-uppercase">
                        {{ isEdit ? "EDIT" : "ADD" }}
                      </div>
                      <div class="wizard-desc">Category</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--end::Wizard Nav-->
            <!--begin::Card-->
            <div class="card card-custom card-shadowless rounded-top-0">
              <!--begin::Body-->
              <div class="card-body p-0">
                <div
                  class="row justify-content-center py-8 px-8 py-lg-15 px-lg-10"
                >
                  <div class="col-xl-12 col-xxl-10">
                    <!--begin::Wizard Form-->
                    <form class="form" id="kt_form">
                      <div class="row justify-content-center">
                        <div class="col-xl-9">
                          <!--begin::Wizard Step 1-->
                          <div
                            class="my-5 step"
                            data-wizard-type="step-content"
                            data-wizard-state="current"
                          >
                            <!--begin::Group-->

                            <div class="form-group row">
                              <label class="col-xl-3 col-lg-3 col-form-label"
                                >Education level</label
                              >
                              <div class="col-lg-9 col-xl-9">
                                <b-form-select
                                  class="form-control form-control-solid form-control-lg"
                                  v-model="selectedEducation"
                                  :options="educations"
                                  :state="educationState"
                                  :disabled="education.length == 0 || isEdit"
                                ></b-form-select>
                                <span class="form-text text-muted"
                                  >Select education</span
                                >
                              </div>
                            </div>

                            <div class="form-group row">
                              <label class="col-xl-3 col-lg-3 col-form-label"
                                >Key</label
                              >
                              <div class="col-lg-9 col-xl-9">
                                <b-form-input
                                  :disabled="isEdit"
                                  :state="keyState"
                                  v-model="key"
                                  class="form-control form-control-solid form-control-lg"
                                ></b-form-input>
                                <span class="form-text text-muted"
                                  >Category Key</span
                                >
                              </div>
                            </div>
                            <!--end::Group-->
                            <!--begin::Group-->
                            <div class="form-group row">
                              <label class="col-xl-3 col-lg-3 col-form-label"
                                >EN</label
                              >
                              <div class="col-lg-9 col-xl-9">
                                <b-form-input
                                  v-model="en"
                                  :state="enState"
                                  class="form-control form-control-solid form-control-lg"
                                ></b-form-input>
                                <span class="form-text text-muted"
                                  >English Name</span
                                >
                              </div>
                            </div>
                            <!--end::Group-->
                            <!--begin::Group-->
                            <div class="form-group row">
                              <label class="col-xl-3 col-lg-3 col-form-label"
                                >AR</label
                              >
                              <div class="col-lg-9 col-xl-9">
                                <b-form-input
                                  v-model="ar"
                                  :state="arState"
                                  class="form-control form-control-solid form-control-lg"
                                ></b-form-input>
                                <span class="form-text text-muted"
                                  >Arabic Name</span
                                >
                              </div>
                            </div>

                            <!--end::Group-->
                          </div>
                          <!--end::Wizard Step 1-->

                          <!--begin::Wizard Actions-->
                          <div
                            class="d-flex justify-content-between border-top pt-10 mt-15"
                          >
                            <div class="mr-2">
                              <c-back-button
                                title="Cancel"
                                inputClass="btn font-weight-bolder px-9 py-4"
                              ></c-back-button>
                              <!-- <b-button
                                @click="onCancelClicked()"
                                class="btn font-weight-bolder px-9 py-4"
                                >Cancel</b-button
                              > -->
                            </div>
                            <div>
                              <b-button
                                @click="onSaveClicked()"
                                variant="primary"
                                class="btn font-weight-bolder px-9 py-4"
                                >Save</b-button
                              >
                            </div>
                          </div>
                          <!--end::Wizard Actions-->
                        </div>
                      </div>
                    </form>
                    <!--end::Wizard Form-->
                  </div>
                </div>
              </div>
              <!--end::Body-->
            </div>
            <!--end::Card-->
          </div>
          <!--end::Wizard-->
        </div>
      </div>
      <!--end::Card-->
    </div>
    <!--end::Container-->
  </div>
</template>
<script>
const arabic = /[\u0600-\u06FF]/;
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  educationCollection,
  categoriesCollection,
} from "@/core/services/firebase.js";
import CBackButton from "@/view/components/CBackButton.vue";
import { mapState } from "vuex";
// import func from '../../../../vue-temp/vue-editor-bridge';
export default {
  name: "category-frm",
  components: {
    CBackButton,
  },
  props: {
    routerKey: {
      type: String,
      default: "",
    },
    otherProp: {
      type: Object,
      default: null,
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Categories" }]);
    this.isEdit = this.routerKey.trim().length != 0;
    this.fillDataOnUpdate();
  },
  data: () => ({
    selectedEducation: "",
    isEdit: false,
    key: "",
    en: "",
    ar: "",
  }),
  computed: {
    ...mapState("fire", ["education"]),
    educations() {
      return this.education.slice(1);
    },
    keyState() {
      return this.key.trim().length > 0 ? true : false;
    },
    enState() {
      return this.en.trim().length > 0 ? true : false;
    },
    arState() {
      return this.ar.trim().length > 0 && arabic.test(this.ar) ? true : false;
    },
    educationState() {
      return (
        this.selectedEducation != null &&
        this.selectedEducation.trim().length > 0
      );
    },
  },
  methods: {
    fillDataOnUpdate() {
      if (!this.isEdit) {
        this.selectedEducation = "";
        return;
      }

      this.selectedEducation = this.$store.getters["fire/getSelectedEducation"];
      if (
        this.selectedEducation == null ||
        this.selectedEducation.trim().length == 0
      ) {
        this.$router.push({ name: "categories" });
        return;
      }

      this.key = this.routerKey;
      this.en = this.otherProp.en;
      this.ar = this.otherProp.ar;
      this.selectedEducation = this.otherProp.education;
    },
    async onSaveClicked() {
      let prefix = "";
      if (
        !this.keyState ||
        !this.enState ||
        !this.arState ||
        this.selectedEducation == null ||
        this.selectedEducation.trim().length == 0
      )
        return;
      let key = this.key.toLowerCase().replace(/\s/g, "");
      if (!this.isEdit) prefix = this.selectedEducation + "_";
      key = prefix + key;
      const payload = {
        en: this.en,
        ar: this.ar,
        text: this.en,
        value: key,
        key: key,
        education: this.selectedEducation,
      };
      try {
        await categoriesCollection.doc(key).set(payload, { merge: true });
      } catch (e) {
        // console.error(e);
      }
      await this.$swal({
        icon: "success",
        title: this.actionText(),
        text: `Category has been ${this.actionText().toLowerCase()}`,
        buttonsStyling: false,
        confirmButtonText: "Ok",
        customClass: {
          confirmButton: "btn font-weight-bold btn-light",
        },
      });

      if (!this.isEdit) this.clear();
    },
    actionText() {
      return this.isEdit ? "Updated" : "Added";
    },
    clear() {
      this.key = "";
      this.en = "";
      this.ar = "";
      this.selectedEducation = "";
    },
  },
};
</script>
