<template>
  <div class="card card-custom gutter-b">
    <!--begin::Body-->
    <div class="card-body p-0">
      <div class="card-toolbar text-right" style="margin-top: 10px">
        <router-link :to="frmLink">
          <i
            class="menu-icon flaticon2-add-1 icon-lg"
            style="margin-right: 20px; color: #82bf6f"
          ></i>
        </router-link>
      </div>
      <div
        class="d-flex align-items-center justify-content-between card-spacer flex-grow-1"
      ></div>
      <!--begin::Chart-->
      <div class="flex-grow-1 card-spacer pb-0 text-center">
        <!-- <div class="font-weight-boldest font-size-h3 pt-2">{{ count }}</div> -->
        <router-link :to="lstLink">
          <div
            class="text-muted text-hover-primary font-weight-bold font-size-lg mt-1"
          >
            {{ title }}
          </div>
        </router-link>
      </div>
      <!--end::Chart-->
      <div
        class="card-body d-flex flex-column text-right"
        style="padding-right: 20px"
      >
        <div>
          <!-- <a
            href="#"
            class="btn btn-primary btn-shadow-hover font-weight-bolder w-100 py-3"
            >Explore</a
          > -->
          <!-- <b-button pill variant="outline-primary">Listing >></b-button> -->
          <!-- <router-link :to="lstLink">
            <i
              class="menu-icon flaticon-list icon-xl"
              style="color: #d2555b"
            ></i>
          </router-link> -->
        </div>
      </div>
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "explore-stat-widget",
  props: ["count", "title", "topLeftIcon", "themeColor", "frmLink", "lstLink"],
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
  },
  mounted() {
    // reference; kt_stats_widget_7_chart
  },
};
</script>
