<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white"
      :class="{
        'login-signin-on': this.state == 'signin',
        'login-signup-on': this.state == 'signup',
        'login-forgot-on': this.state == 'forgot',
      }"
      id="kt_login"
    >
      <!--begin::Aside-->
      <div
        class="login-aside d-flex flex-column flex-row-auto"
        style="background-color: #fff"
      >
        <div class="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
          <a href="#" class="text-center mb-10">
            <img
              src="media/logos/971tutors-logo.jpeg"
              class="max-h-70px"
              alt=""
            />
          </a>
          <h3
            class="font-weight-bolder text-primary text-center font-size-h4 font-size-h1-lg"
          >
            Administration Portal<br />
          </h3>
        </div>
        <div
          class="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center"
          :style="{ backgroundImage: `url(${backgroundImage})` }"
        ></div>
      </div>
      <!--begin::Aside-->
      <!--begin::Content-->
      <div
        class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto"
      >
        <div class="d-flex flex-column-fluid flex-center">
          <!--begin::Signin-->
          <div class="login-form login-signin">
            <div class="pb-lg-0 pb-5 text-center">
              <button
                @click="signInWithGoogle"
                type="button"
                class="btn btn-primary btn-lg font-weight-bolder px-8 py-4 my-3 font-size-lg"
              >
                <span class="svg-icon svg-icon-md">
                  <inline-svg src="media/svg/social-icons/google.svg" /> </span
                >Sign in with Google
              </button>
            </div>
            <div
              style="min-height: 80.9px"
              class="pb-13 pt-lg-0 pt-5 text-center"
            >
              <b-badge v-if="!gt_isAdmin && isAuthenticated" variant="danger"
                >Insufficient Privilege</b-badge
              >
              <h3
                class="font-weight-bolder text-danger font-size-h4 font-size-h1-lg"
              ></h3>
            </div>
          </div>
          <!--end::Signin-->
        </div>
        <!--begin::Content footer-->
        <div
          class="d-flex justify-content-lg-start justify-content-center align-items-end py-7 py-lg-0"
        >
          <a
            href="#"
            class="text-primary font-weight-bolder font-size-h5"
            v-show="false"
            >Terms</a
          >
          <a
            href="#"
            class="text-primary ml-10 font-weight-bolder font-size-h5"
            v-show="false"
            >Plans</a
          >
          <a
            href="#"
            class="text-primary ml-10 font-weight-bolder font-size-h5"
            v-show="false"
            >Contact Us</a
          >
        </div>
        <!--end::Content footer-->
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import KTUtil from "@/assets/js/components/util";
import { mapGetters, mapState } from "vuex";
import { LOGIN, LOGOUT, REGISTER } from "@/core/services/store/auth.module";
import Swal from "sweetalert2";
import { auth, googleAuthProvider } from "@/core/services/firebase.js";

export default {
  name: "login-1",
  data() {
    return {
      state: "signin",
    };
  },
  computed: {
    ...mapGetters("auth", ["isAuthenticated", "gt_isAdmin"]),
    ...mapState({
      errors: (state) => state.auth.errors,
    }),

    backgroundImage() {
      return (
        process.env.BASE_URL + "media/svg/illustrations/login-visual-6.svg"
      );
    },
  },
  created() {
    auth
      .getRedirectResult()
      .then((result) => {
        if (this.isAuthenticated) {
          this.$router.push({ path: "/" });
        } else if (result.user) {
          this.$store.dispatch("auth/login", result.user);
          this.$router.push({ path: "/" });
        } else {
          this.$store.commit("auth/setUser", null);
        }
      })
      .catch(function (error) {
        // console.error(error);
      });
  },
  methods: {
    signInWithGoogle() {
      console.log('sign in');
      auth.signInWithRedirect(googleAuthProvider).then(user => {
        console.log('logged in');
      }).catch((error) => {
        this.$store.commit("auth/setUser", null);
      });
    },
  },
};
</script>
