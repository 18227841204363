<template>
  <div class="d-flex flex-column-fluid">
    <!--begin::Container-->
    <div class="container">
      <!--begin::Card-->
      <div class="card card-custom card-transparent">
        <div class="card-body p-0">
          <!--begin::Wizard-->
          <div
            class="wizard wizard-4"
            id="kt_wizard"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
          >
            <!--begin::Wizard Nav-->
            <div class="wizard-nav">
              <div class="wizard-steps">
                <div
                  class="wizard-step"
                  data-wizard-type="step"
                  data-wizard-state="current"
                >
                  <div class="wizard-wrapper">
                    <div class="wizard-number">
                      <i
                        style="color: white"
                        class="menu-icon flaticon-paper-plane"
                      ></i>
                    </div>
                    <div class="wizard-label">
                      <div class="wizard-title text-uppercase">LISTING</div>
                      <div class="wizard-desc">Applications</div>
                    </div>
                  </div>
                </div>
                <!--begin::Wizard Nav-->
                <div class="wizard-step" data-wizard-type="step">
                  <div class="wizard-wrapper">
                    <div class="wizard-label">
                      <b-button
                        @click="onPublishClicked()"
                        variant="primary"
                        class="btn font-weight-bolder px-9 py-4"
                      >
                        <i
                          style="color: #fff"
                          class="menu-icon flaticon2-send-1"
                        ></i
                        >Publish</b-button
                      >
                    </div>
                  </div>
                </div>
                <!--end::Wizard Nav-->
              </div>
            </div>
            <!--end::Wizard Nav-->

            <!--begin::Card-->
            <div
              class="card card-custom card-shadowless rounded-top-0 text-center"
              style="min-height: 72vh"
            >
              <!--begin::Body-->

              <div class="card-body p-0">
                <div class="row py-8 px-8 py-lg-15 px-lg-10">
                  <div class="col-lg-6 col-xl-6">
                    <div class="col-lg-6 col-xl-6">
                      <span class="form-text text-muted text-left"
                        >Select session's status:</span
                      >
                      <b-form-select
                        class="form-control form-control-solid form-control-lg"
                        v-model="selectedStatus"
                        :options="statuses"
                        @change="onStatusChanged"
                      >
                      </b-form-select>
                    </div>
                  </div>
                  <v-data-table
                    style="width: 100%; min-height: 100%"
                    class="font-weight-bolder"
                    :headers="headers"
                    :items="getApplicationsByStatus"
                    disable-pagination
                    :hide-default-footer="true"
                    :search="search"
                    :custom-filter="filterText"
                    :loading="tableLoading"
                    loading-text="Please wait"
                    dense
                    @click:row="goToTutorPage"
                  >
                    <template v-slot:top>
                      <v-text-field
                        v-model="search"
                        label="Search"
                        class="mx-4"
                      ></v-text-field>
                    </template>

                    <template v-slot:[`item.date`]="{ item }">
                      <span
                        class="label label-lg font-weight-bold label-inline date-label"
                        >{{ toDate(item.date) }}
                      </span>
                    </template>

                    <template v-slot:[`item.status`]="{ item }">
                      <span
                        style="cursor: pointer"
                        @click.stop.prevent="onStatusClicked(item)"
                        class="label label-lg font-weight-bold label-inline"
                        :class="
                          statuses[getStatusIndexByKey(item.status)].class
                        "
                        >{{ statuses[getStatusIndexByKey(item.status)].text }}
                      </span>
                    </template>
                  </v-data-table>
                </div>
              </div>
              <!--end::Body-->
            </div>
            <!--end::Card-->
          </div>
          <!--end::Wizard-->
        </div>
      </div>
      <!--end::Card-->
    </div>
    <!--end::Container-->
  </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters, mapState } from "vuex";
import { copyClipboard } from "@/core/utils/copyclipboard.js";
import { firebase } from "@firebase/app";

import {
  newBatch,
  applicationsCollection,
  tutorSubjectCollection,
} from "@/core/services/firebase.js";
import { sendMail } from "@/core/services/firebase.js";
import { l_publishAll } from "../../../core/utils/lib-publish.js";
export default {
  name: "applications-lst",
  components: {},
  computed: mapGetters("fire", ["getApplicationsByStatus","getTutorById"]),
  async mounted() {
    let l_status = this.status || "";
    this.onStatusChanged(l_status);
    this.selectedStatus = this.$store.getters[
      "fire/getSelectedApplicationStatus"
    ];
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Applications" }]);
  },
  data() {
    return {
      showDialog: true,
      applicationStatus: "",
      selectedStatus: "",
      tableLoading: false,
      search: "",
      headers: [
        {
          text: "Tutor's email",
          value: "tutor",
        },
        {
          text: "Tutor's name",
          value: "tutorName",
        },
        {
          text: "Curriculum",
          value: "curriculums",
        },
        {
          text: "Subject",
          value: "en",
        },
        {
          text: "Grade",
          value: "grade",
        },
        {
          text: "Applied on",
          value: "date",
        },
        {
          text: "Status",
          value: "status",
        },
      ],
      statuses: [
        {
          value: "",
          text: "All",
          class: "",
        },
        {
          value: "A",
          text: "Available",
          class: "label-light-success",
        },
        {
          value: "R",
          text: "Under Review",
          class: "label-light-warning",
        },
        {
          value: "D",
          text: "Denied",
          class: "label-light-danger",
        },
      ],
    };
  },
  props: {
    status: { type: String },
  },

  methods: {
    goToTutorPage(item) {
      const tutor = this.getTutorById(item.tutor);
      if(tutor[0] != null)
      {
        this.$store.commit("fire/setSelectedTutor", tutor[0]);
        this.$router.push({
          name: "TutorEditForm",
          params: {
            routerKey: tutor[0].id,
          },
        });
      }
    },
    async onPublishClicked() {
      await l_publishAll(this);
    },
    toDate(timestamp) {
      return this.$moment(timestamp.toDate()).format("DD-MM-YYYY");
    },
    toDateEmail(timestamp) {
      return this.$moment(timestamp.toDate()).format("Do [of] MMMM YYYY");
    },
    getStatusIndexByKey(key) {
      for (let i = 0; i < this.statuses.length; i++) {
        if (this.statuses[i].value == key) return i;
      }
      return 0;
    },
    onStatusChanged(value) {
      this.$store.commit("fire/setSelectedApplicationStatus", value);
    },

    filterText(value, search, item) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1
      );
    },
    async onStatusClicked(application) {
      /* inputOptions can be an object or Promise */
      const inputOptions = new Promise((resolve) => {
        setTimeout(() => {
          resolve({
            D: "Deny",
            R: "Review",
            A: "Activate",
          });
        }, 500);
      });

      const { value: status } = await this.$swal.fire({
        title: "Select Application Status",
        input: "radio",
        inputOptions: inputOptions,
        inputValidator: (value) => {
          if (!value) {
            return "Please choose an option!";
          }
        },
      });

      if (status) {
        let payload = { ...application, ...{ status: status } };
        await this.dbChangeApplicationStatus(payload);
        const temp = await sendMail({
          application: payload,
          date: this.toDateEmail(application.date),
          emailevent: "TS",
        });

        this.$swal.fire({
          icon: "success",
          title: "Updated",
          text: "Tutor Application has been updated",
          customClass: {
            confirmButton: "btn font-weight-bold btn-light",
            buttonsStyling: false,
          },
        });
      }
    },
    async dbChangeApplicationStatus(payload) {
      let batch = newBatch();
      const applicationRef = applicationsCollection.doc(
        `${payload.tutor}_${payload.subject}`
      );

      const tutorSubjectRef = tutorSubjectCollection.doc(payload.tutor);
      const tutorSubjectDoc = tutorSubjectRef
        .collection("subjects")
        .doc(payload.subject);

      batch.set(applicationRef, { status: payload.status }, { merge: true });
      batch.set(tutorSubjectDoc, { status: payload.status }, { merge: true });

      await batch.commit();
    },
  },
};
</script>
