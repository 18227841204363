<template>
  <div class="card card-custom gutter-b" style="height: 150px">
    <div class="card-body">
      <span class="svg-icon svg-icon-3x">
        <inline-svg :src="icon" />
      </span>
      <div class="text-dark font-weight-bolder font-size-h2 mt-3">
        {{ count }}
      </div>
      <a
        @click="navigateTo"
        style="cursor: pointer"
        class="text-muted text-hover-primary font-weight-bold font-size-lg mt-1"
        >{{ title | withS(count) }}</a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "count-stat-widget",
  props: ["title", "count", "icon", "to"],
  filters: {
    withS: function (value, count) {
      if (count != 1) return value;
      return value.substring(0, value.length - 1);
    },
  },
  methods: {
    navigateTo() {
      if (!this.to) return;
      if (typeof this.to === "string") this.$router.push({ path: this.to });
      else if (typeof this.to === "object") this.$router.push(this.to);
    },
  },
};
</script>

<style></style>
