<template>
  <div class="d-flex flex-column-fluid" data-app>
    <!--begin::Container-->
    <div class="container">
      <!--begin::Card-->
      <div class="card card-custom card-transparent">
        <div class="card-body p-0">
          <!--begin::Wizard-->
          <div
            class="wizard wizard-4"
            id="kt_wizard"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
          >
            <!--begin::Wizard Nav-->
            <div class="wizard-nav">
              <div class="wizard-steps">
                <div
                  class="wizard-step"
                  data-wizard-type="step"
                  data-wizard-state="current"
                >
                  <div class="wizard-wrapper">
                    <div class="wizard-number">
                      <i
                        style="color: white"
                        class="menu-icon flaticon-chat"
                      ></i>
                    </div>
                    <div class="wizard-label">
                      <div class="wizard-title text-uppercase">LISTING</div>
                      <div class="wizard-desc">Chats</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--end::Wizard Nav-->
            <!--begin::Card-->
            <div
              class="card card-custom card-shadowless rounded-top-0 text-center"
              style="min-height: 72vh"
            >
              <div class="card-body p-0">
                <div class="px-8 py-lg-5 px-lg-5">
                  <span
                    class="form-text text-muted text-left"
                    style="margin-left: 10px"
                    >Student</span
                  >
                  <v-combobox
                    :v-model="selectedStudent"
                    hide-selected
                    :items="students"
                    @change="onChangeStudent"
                  ></v-combobox>
                </div>
                <div class="px-8 py-lg-5 px-lg-5">
                  <span
                    class="form-text text-muted text-left"
                    style="margin-left: 10px"
                    >Tutor</span
                  >
                  <v-combobox
                    :v-model="selectedTutor"
                    hide-selected
                    :items="tutors"
                    @change="onChangeTutor"
                  ></v-combobox>
                </div>
                <div class="px-8 px-lg-5 text-center">
                  <b-button
                    :disabled="isdisable"
                    @click="openChat"
                    variant="primary"
                    class="btn font-weight-bolder px-9 py-4"
                    >Search</b-button
                  >
                  <b-modal
                    ref="chat"
                    :title="co_chatTitle()"
                    hide-footer
                    class="p-0"
                  >
                    <cmp-chat
                      :student="selectedStudent"
                      :tutor="selectedTutor"
                      style="height: 80vh !important"
                    ></cmp-chat>
                  </b-modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import c_chat from "@/view/components/cmp-chat.vue";
import {
  tutorsCollection,
  studentsCollection,
  database,
} from "@/core/services/firebase.js";
export default {
  name: "chats",
  components: {
    "cmp-chat": c_chat,
  },
  data() {
    return {
      selectedTutor: "",
      selectedStudent: "",
      tutors: [],
      students: [],
    };
  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Chats" }]);
    this.getTutors();
    this.getStudents();
  },
  computed: {
    isdisable() {
      let value =
        this.selectedTutor.trim().length == 0 ||
        this.selectedStudent.trim().lenth == 0;
      return value;
    },
  },
  methods: {
    async getTutors() {
      const randomArray = [];
      tutorsCollection.get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const email = doc.data().account.email;

          randomArray.push(email);
        });
      });
      this.tutors = randomArray;
    },
    async getStudents() {
      const randomArray = [];
      studentsCollection.get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const email = doc.data().account.email;

          randomArray.push(email);
        });
      });
      this.students = randomArray;
    },
    async openChat() {
      let isnull = false;
      await database
        .ref("chat/" + this.getchatid())
        .once("value", (snapshot) => {
          isnull = snapshot.val() == null ? false : true;
        });

      if (isnull) {
        this.$refs["chat"].show();
      } else {
        await this.$swal({
          icon: "warning",
          title: "Warning",
          text: "Chat not available",
          buttonsStyling: false,
          confirmButtonText: "Ok",
          customClass: {
            confirmButton: "btn font-weight-bold btn-light",
          },
        });
      }
    },
    getchatid() {
      let id = `${this.selectedStudent}|${this.selectedTutor}`;
      id = id.replaceAll(".", "-");
      return id;
    },
    co_chatTitle() {
      let title =
        "Student: " + this.selectedStudent + " - tutor: " + this.selectedTutor;

      return title;
    },
    onChangeTutor(value) {
      this.selectedTutor = value;
    },
    onChangeStudent(value) {
      this.selectedStudent = value;
    },
  },
};
</script>

<style>
.modal-body {
  padding: 0 !important;
}
</style>
