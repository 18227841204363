<template>
  <div class="d-flex flex-column-fluid">
    <!--begin::Container-->
    <div class="container" ref="container">
      <!--begin::Card-->
      <div class="card card-custom card-transparent">
        <div class="card-body p-0">
          <!--begin::Wizard-->
          <div
            class="wizard wizard-4"
            id="kt_wizard"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
          >
            <!--begin::Wizard Nav-->
            <div class="wizard-nav">
              <div class="wizard-steps">
                <div
                  class="wizard-step"
                  data-wizard-type="step"
                  data-wizard-state="current"
                >
                  <div class="wizard-wrapper">
                    <div class="wizard-number">
                      <i
                        style="color: white"
                        class="menu-icon flaticon-graph"
                      ></i>
                    </div>
                    <div class="wizard-label">
                      <div class="wizard-title text-uppercase">LISTING</div>
                      <div class="wizard-desc">Reports</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--end::Wizard Nav-->
            <!--begin::Card-->
            <div
              class="card card-custom card-shadowless rounded-top-0 text-center"
              style="min-height: 72vh"
            >
              <div class="card-body p-0">
                <div class="px-8 py-lg-5 px-lg-5">
                  <v-btn-toggle
                    v-model="reportYaxis"
                    tile
                    @change="refreshChart"
                  >
                    <v-btn active-class="active-report" value="totalSessions"
                      >Total sessions
                    </v-btn>
                    <v-btn
                      active-class="active-report"
                      value="deliveredSessions"
                      >Delivered sessions
                    </v-btn>
                    <v-btn active-class="active-report" value="totalRevenue"
                      >Total revenue</v-btn
                    >
                  </v-btn-toggle>
                </div>
                <div class="px-8 py-lg-5 px-lg-5">
                  <v-radio-group v-model="row" row @change="changeChart">
                    <v-radio label="Daily" value="Daily"></v-radio>
                    <v-radio label="Weekly" value="Weekly"></v-radio>
                    <v-radio label="Monthly" value="Monthly"></v-radio>
                    <v-radio label="Yearly" value="Yearly"></v-radio>
                  </v-radio-group>
                </div>
                <div class="px-8 py-lg-5 px-lg-5 chart">
                  <apexchart
                    :options="options"
                    :series="series"
                    :key="key"
                    height="500px"
                    :width="width"
                    ref="chart"
                    type="bar"
                  ></apexchart>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import VueApexCharts from "vue-apexcharts";
import moment from "moment";
import { sessionsCollection } from "@/core/services/firebase.js";
import { mapGetters } from "vuex";
//Vue.component("apexchart", VueApexCharts);
export default {
  name: "reports",
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      row: null,
      key: "ss",
      yAxisText: "Total Sessions",
      width: "100%",
      options: {
        xaxis: {
          categories: ["Jan"],
          labels: {
            show: true,
            rotate: -90,
            rotateAlways: true,
          },
          title: {
            text: "Dates",
          },
        },
        yaxis: {
          labels: {
            show: true,
            rotate: -90,
            rotateAlways: true,
          },
          title: {
            text: "NbOfSessions",
          },
        },
      },
      series: [
        {
          name: "Nb of Sessions",
          data: [1],
        },
      ],
    };
  },
  computed: {
    ...mapGetters("fire", ["getReportYAxis"]),
    reportYaxis: {
      get: function () {
        let reportYaxis = this.getReportYAxis;
        if (reportYaxis) return reportYaxis;
        return "totalSessions";
      },
      set: function (value) {
        this.$store.commit("fire/setReportYAxis", value);
      },
    },
  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Reports" }]);
    this.row = "Daily";
    this.reportYaxis = "totalSessions";
    setTimeout(() => {
      this.changeChart("Daily");
      let dynamicWidth = this.series[0].data.length * 30;
      this.width =
        dynamicWidth < this.$refs.container.innerWidth ? "100%" : dynamicWidth;
    }, 1000);
  },
  methods: {
    getNrOfDailyDeliveredSessionsByDate(date) {
      return this.$store.getters["fire/getNrOfDailyDeliveredSessionsByDate"](
        date
      );
    },
    getNrOfWeeklyDeliveredSessionsByDate(date) {
      return this.$store.getters["fire/getNrOfWeeklyDeliveredSessionsByDate"](
        date
      );
    },
    getNrOfMonthlyDeliveredSessionsByDate(date) {
      return this.$store.getters["fire/getNrOfMonthlyDeliveredSessionsByDate"](
        date
      );
    },
    getNrOfYearlyDeliveredSessionsByDate(date) {
      return this.$store.getters["fire/getNrOfYearlyDeliveredSessionsByDate"](
        date
      );
    },
    getDailyRevenueByDate(date) {
      return this.$store.getters["fire/getDailyRevenueByDate"](date);
    },
    getWeeklyRevenueByDate(date) {
      return this.$store.getters["fire/getWeeklyRevenueByDate"](date);
    },
    getMonthlyRevenueByDate(date) {
      return this.$store.getters["fire/getMonthlyRevenueByDate"](date);
    },
    getYearlyRevenueByDate(date) {
      return this.$store.getters["fire/getYearlyRevenueByDate"](date);
    },
    getNrOfDailySessionsByDate(date) {
      return this.$store.getters["fire/getNrOfDailySessionsByDate"](date);
    },
    getNrOfWeeklySessionsByDate(date) {
      return this.$store.getters["fire/getNrOfWeeklySessionsByDate"](date);
    },
    getNrOfMonthlySessionsByDate(date) {
      return this.$store.getters["fire/getNrOfMonthlySessionsByDate"](date);
    },
    getNrOfYearlySessionsByDate(date) {
      return this.$store.getters["fire/getNrOfYearlySessionsByDate"](date);
    },
    refreshChart() {
      let value = this.row;
      let today = moment().toDate();
      let todayminus30days = moment().subtract(30, "days").toDate();
      let todatminus26weeks = moment().subtract(26, "weeks");
      if (value == "Daily") {
        this.setDays(todayminus30days, today);
        this.key = Math.random();
      } else if (value == "Weekly") {
        this.setWeeks(todatminus26weeks, today);
        this.key = Math.random();
      } else if (value == "Monthly") {
        this.setMonths(today);
        this.key = Math.random();
      } else if (value == "Yearly") {
        this.setYearly(today);
        this.key = Math.random();
      }

      if (this.getReportYAxis == "deliveredSessions")
        this.yAxisText = "Delivered sessions";
      else if (this.getReportYAxis == "totalSessions")
        this.yAxisText = "Total sessions";
      else if (this.getReportYAxis == "totalRevenue")
        this.yAxisText = "Total revenue";

      this.options.yaxis.title.text = this.yAxisText;

      this.$forceUpdate();
    },
    changeChart(value) {
      let today = moment().toDate();
      let todayminus30days = moment().subtract(30, "days").toDate();
      let todatminus26weeks = moment().subtract(26, "weeks");
      if (value == "Daily") {
        this.setDays(todayminus30days, today);
        this.key = Math.random();
      } else if (value == "Weekly") {
        this.setWeeks(todatminus26weeks, today);
        this.key = Math.random();
      } else if (value == "Monthly") {
        this.setMonths(today);
        this.key = Math.random();
      } else if (value == "Yearly") {
        this.setYearly(today);
        this.key = Math.random();
      }

      if (this.getReportYAxis == "deliveredSessions")
        this.yAxisText = "Delivered sessions";
      else if (this.getReportYAxis == "totalSessions")
        this.yAxisText = "Total sessions";
      else if (this.getReportYAxis == "totalRevenue")
        this.yAxisText = "Total revenue";

      this.options.yaxis.title.text = this.yAxisText;

      this.$forceUpdate();
    },
    setDays(start, end) {
      this.series[0].data = [];
      for (
        var arr = [], dt = new Date(start);
        dt <= end;
        dt.setDate(dt.getDate() + 1)
      ) {
        arr.push(
          dt.getDate() + "/" + moment(dt).startOf("month").format("MMM")
        );
        if (this.getReportYAxis == "deliveredSessions")
          this.series[0].data.push(
            this.getNrOfDailyDeliveredSessionsByDate(dt)
          );
        else if (this.getReportYAxis == "totalSessions")
          this.series[0].data.push(this.getNrOfDailySessionsByDate(dt));
        else if (this.getReportYAxis == "totalRevenue")
          this.series[0].data.push(this.getDailyRevenueByDate(dt));
      }
      this.options.xaxis.categories = arr;
    },
    setWeeks(start, end) {
      this.series[0].data = [];
      for (
        var arr = [], dt = new Date(start);
        dt <= end;
        dt.setDate(dt.getDate() + 7)
      ) {
        arr.push(
          moment(dt).week() + "/" + moment(dt).startOf("year").format("YY")
        );
        if (this.getReportYAxis == "deliveredSessions")
          this.series[0].data.push(
            this.getNrOfWeeklyDeliveredSessionsByDate(dt)
          );
        else if (this.getReportYAxis == "totalSessions")
          this.series[0].data.push(this.getNrOfWeeklySessionsByDate(dt));
        else if (this.getReportYAxis == "totalRevenue")
          this.series[0].data.push(this.getWeeklyRevenueByDate(dt));
      }
      this.options.xaxis.categories = arr;
    },
    setMonths(today) {
      this.series[0].data = [];
      var arr = [];
      let currentMonth = moment(today);
      let prevMonth = currentMonth;
      let series = [];
      for (var i = 0; i < 36; i = i + 1) {
        arr.push(
          prevMonth.startOf("month").format("MMM") +
            "/" +
            moment(prevMonth).startOf("year").format("YY")
        );
        if (this.getReportYAxis == "deliveredSessions")
          series.push(
            this.getNrOfMonthlyDeliveredSessionsByDate(prevMonth.toDate())
          );
        else if (this.getReportYAxis == "totalSessions")
          series.push(this.getNrOfMonthlySessionsByDate(prevMonth.toDate()));
        else if (this.getReportYAxis == "totalRevenue")
          series.push(this.getMonthlyRevenueByDate(prevMonth.toDate()));

        prevMonth = moment(prevMonth).subtract(1, "months");
      }
      arr.reverse();
      series.reverse();
      this.series[0].data = series;
      this.options.xaxis.categories = arr;
    },
    setYearly(today) {
      this.series[0].data = [];
      var arr = [];
      let currentyear = moment(today);
      let prevyear = currentyear;
      let series = [];
      for (var i = 0; i < 6; i = i + 1) {
        arr.push(prevyear.startOf("year").format("YYYY"));

        if (this.getReportYAxis == "deliveredSessions")
          series.push(
            this.getNrOfYearlyDeliveredSessionsByDate(prevyear.toDate())
          );
        else if (this.getReportYAxis == "totalSessions")
          series.push(this.getNrOfYearlySessionsByDate(prevyear.toDate()));
        else if (this.getReportYAxis == "totalRevenue")
          series.push(this.getYearlyRevenueByDate(prevyear.toDate()));

        prevyear = moment(prevyear).subtract(1, "years");
      }
      series.reverse();
      arr.reverse();
      this.series[0].data = series;
      this.options.xaxis.categories = arr;
    },
  },
};
</script>

<style>
.chart {
  overflow: auto;
}
.active-report {
  background: #82bf6f !important;
}
</style>
