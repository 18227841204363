<template>
  <div style="min-width: 70px; text-align: center">
    <i
      @click="m_onApprove"
      style="margin-right: 10px; color: #82bf6f"
    >    <p>Click for more info</p>
</i>
    <i @click="m_onDeny" style="color: red" class="la la-close"></i>
  </div>
</template>

<script>
export default {
  name: "cmp-actionsBankTransfer",
};
</script>

<style></style>
