<template>
  <v-app>
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container">
        <!--begin::Card-->
        <div class="card card-custom card-transparent">
          <div class="card-body p-0">
            <!--begin::Wizard-->
            <div
              class="wizard wizard-4"
              id="kt_wizard"
              data-wizard-state="step-first"
              data-wizard-clickable="true"
            >
              <!--begin::Wizard Nav-->
              <div class="wizard-nav">
                <div class="wizard-steps">
                  <div
                    class="wizard-step"
                    data-wizard-type="step"
                    data-wizard-state="current"
                  >
                    <div class="wizard-wrapper">
                      <div class="wizard-number">
                        <i
                          style="color: white"
                          class="menu-icon flaticon-share"
                        ></i>
                      </div>
                      <div class="wizard-label">
                        <div class="wizard-title text-uppercase">LISTING</div>
                        <div class="wizard-desc">Events</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--end::Wizard Nav-->
              <!--begin::Card-->
              <div
                class="
                  card card-custom card-shadowless
                  rounded-top-0
                  text-center
                "
                style="min-height: 72vh"
              >
                <div class="card-body p-0">
                  <div class="px-8 py-lg-5 px-lg-5">
                    <v-file-input
                      v-model="image"
                      show-size
                      accept="image/*"
                      label="Upload background image"
                      outlined
                    ></v-file-input>
                  </div>
                  <div class="px-8 py-lg-5 px-lg-5">
                    <v-file-input
                      v-model="video"
                      show-size
                      accept="video/mp4,video/x-m4v,video/*"
                      label="Upload video"
                      outlined
                    ></v-file-input>
                  </div>
                  <div class="px-8 py-lg-5 px-lg-5">
                    <v-text-field
                      v-model="title"
                      label="Video title"
                      outlined
                    ></v-text-field>
                  </div>
                  <div class="px-8 py-lg-5 px-lg-5">
                    <v-textarea
                      v-model="description"
                      name="input-7-4"
                      label="Video description text"
                      outlined
                      auto-grow
                    ></v-textarea>
                  </div>
                  <div class="px-8 py-lg-5 px-lg-5">
                    <span
                      class="form-text text-muted text-left"
                      style="margin-left: 10px"
                      >Statistics numbers</span
                    >
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="teachersNumber"
                          label="Teachers"
                          type="number"
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="studentsNumber"
                          label="Students"
                          type="number"
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="familiesNumber"
                          label="Families"
                          type="number"
                          outlined
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </div>
                  <div class="px-8 px-lg-5 text-center">
                    <b-button
                      :loading="loading"
                      :disabled="is_disabled"
                      variant="primary"
                      @click="save"
                      class="btn font-weight-bolder px-9 py-4"
                      >Save</b-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  eventsCollection,
  fb_eventsStorageref,
  eventsRef,
} from "@/core/services/firebase.js";
export default {
  name: "events",
  data() {
    return {
      title: "",
      description: "",
      video: null,
      image: null,
      loading: false,
      teachersNumber: "",
      studentsNumber: "",
      familiesNumber: "",
    };
  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Events" }]);
    let temp = await eventsRef.get();
    temp = temp.data();

    if (temp.video != null) {
      this.video = temp.video;
    }
    if (temp.image != null) {
      this.image = temp.image;
    }
    if (temp.description.trim().length != 0) {
      this.description = temp.description;
    }
    if (temp.title.trim().length != 0) {
      this.title = temp.title;
    }
    if (temp.teachersNumber.trim().length != 0) {
      this.teachersNumber = temp.teachersNumber;
    }
    if (temp.studentsNumber.trim().length != 0) {
      this.studentsNumber = temp.studentsNumber;
    }
    if (temp.familiesNumber.trim().length != 0) {
      this.familiesNumber = temp.familiesNumber;
    }
  },
  computed: {
    is_disabled() {
      return (
        this.familiesNumber.trim().length == 0 &&
        this.studentsNumber.trim().length == 0 &&
        this.teachersNumber.trim().length == 0 &&
        this.description.trim().length == 0 &&
        this.title.trim().length == 0 &&
        this.image == null &&
        this.video == null
      );
    },
  },
  methods: {
    async m_uploadevents(p_file, p_type) {
      if (p_file == null) return null;
      var l_blob = p_file.slice(0, p_file.size, p_file.type);
      var l_file = new File(
        [l_blob],
        `${p_type}.${p_file.name.split(".")[1]}`,
        {
          type: p_file.type,
        }
      );
      const temp = fb_eventsStorageref.child(p_type);
      try {
        const p_snapshot = await temp.put(l_file);
        return await temp.getDownloadURL();
      } catch (p_error) {
        return null;
      }
    },
    async save() {
      try {
        this.loading = true;
        const img = await this.m_uploadevents(this.image, "image");
        const vid = await this.m_uploadevents(this.video, "video");
        const payload = {};
        if (this.title != "") {
          payload.title = this.title;
        }
        if (this.description != "") {
          payload.description = this.description;
        }
        if (img != null) {
          payload.image = img;
        }
        if (vid != null) {
          payload.video = vid;
        }
        if (this.teachersNumber != "") {
          payload.teachersNumber = this.teachersNumber;
        }
        if (this.studentsNumber != "") {
          payload.studentsNumber = this.studentsNumber;
        }
        if (this.familiesNumber != "") {
          payload.familiesNumber = this.familiesNumber;
        }
        await eventsCollection.doc("events").set(payload, { merge: true });
        this.loading = false;
        await this.$swal({
          icon: "success",
          title: "Success",
          text: `Events has been updated`,
          buttonsStyling: false,
          confirmButtonText: "Ok",
          customClass: {
            confirmButton: "btn font-weight-bold btn-light",
          },
        });
      } catch (e) {
        this.loading = false;
      }
    },
  },
};
</script>

<style></style>
