import { dataCollection } from "../services/firebase";

export const l_publishAll = async (l_vue) => {
  try {
    let l_issuccessCurriculums = await l_publishCurriculums(l_vue);
    let l_issuccessCategories = await l_publishCategories(l_vue);
    let l_issuccessSubjects = await l_publishSubjects(l_vue);
    let l_issuccess =
      l_issuccessCategories && l_issuccessCurriculums && l_issuccessSubjects;
    m_popup(l_vue, "Data", l_issuccess);
  } catch (e) {
    m_popup(l_vue, "Data", false);
  }
};

export const l_publishCurriculums = async (l_vue) => {
  let value = true;
  let l_payload = {};
  let l_curriculums = l_vue.$store.state.fire.curriculums;
  if (l_curriculums.length == 0) {
    value = false;
    return value;
  }
  l_curriculums.forEach((p_element) => {
    l_payload[p_element.id] = Object.assign({}, p_element);
  });
  try {
    await dataCollection.doc("curriculums").set(l_payload);
    return value;
  } catch (e) {
    // console.error(e);
  }
};

export const l_publishCategories = async (l_vue) => {
  let value = true;
  let l_payload = { s: {}, u: {}, l: {}, o: {} };
  l_vue.$store.commit("fire/setSelectedEducation", "a");
  let l_categoriesAll = l_vue.$store.state.fire.categories;
  if (l_categoriesAll.length == 0) {
    value = false;
    return value;
  }

  l_categoriesAll.forEach((p_category) => {
    var nbOfSubjects = l_vue.$store.getters["fire/getSubjects"](
      p_category.key,
      true
    ).length;

    Object.assign(l_payload[p_category.education], {
      [p_category.key]: {
        en: p_category.en,
        ar: p_category.ar,
        count: nbOfSubjects,
      },
    });
  });

  try {
    await dataCollection.doc("categories").set(l_payload);
    return value;
  } catch (e) {
    // console.error(e);
  }
};

export const l_publishSubjects = async (l_vue) => {
  let value = true;
  let l_payload = {};
  let subjects = l_vue.$store.state.fire.subjects;
  if (subjects.length == 0) {
    value = false;
    return value;
  }

  let l_applications = l_vue.$store.state.fire.applications;
  let tutors = null;
  subjects.forEach((subject) => {
    tutors = {};
    l_payload[subject.key] = { e: subject.en };
    l_applications.forEach((application) => {
      if (application.subject == subject.key && application.status == "A") {
        tutors[application.tutor] = {};
        if (application.tutor) {
          if (application.grade)
            tutors[application.tutor]["g"] = application.grade;
          if (application.curriculums && application.curriculums.length > 0)
            tutors[application.tutor]["c"] = application.curriculums;
        }
      }
    });
    if (Object.keys(tutors).length !== 0) l_payload[subject.key].t = tutors;
  });
  try {
    await dataCollection.doc("subjects").set(l_payload);
    return value;
  } catch (e) {
    // console.error(e);
  }
};

function m_popup(l_vue, p_context, p_success) {
  let l_payload = {
    buttonsStyling: false,
    confirmButtonText: "Ok",
    customClass: {
      confirmButton: "btn font-weight-bold btn-light",
    },
  };
  l_payload.icon = p_success ? "success" : "error";
  l_payload.title = p_success ? "Published" : "Oops";
  l_payload.text = p_success
    ? `${p_context} have been published`
    : "Something went wrong";
  l_vue.$swal(l_payload);
}
