<template>
  <div>
    <!--begin::Dashboard-->
    <div class="row">
      <!-- <div class="col-xxl-12">
        <widget-1></widget-1>
      </div> -->
      <div class="col-xl-4">
        <count-stat-widget
          :to="{ name: 'sessions', params: { status: 'A' } }"
          title="Available sessions"
          :count="getAvailableSessionsCount"
          icon="media/svg/icons/Devices/Android.svg"
        ></count-stat-widget>
      </div>
      <div class="col-xl-4">
        <count-stat-widget
          :to="{ name: 'sessions', params: { status: 'C' } }"
          title="Completed sessions"
          :count="getCompletedSessionsCount"
          icon="media/svg/icons/Devices/Android.svg"
        ></count-stat-widget>
      </div>
      <div class="col-xl-4">
        <count-stat-widget
          :to="{ name: 'sessions', params: { status: '' } }"
          title="Total sessions"
          :count="getTotalSessionsCount"
          icon="media/svg/icons/Devices/Android.svg"
        ></count-stat-widget>
      </div>
      <div class="col-xl-4">
        <count-stat-widget
          :to="{ name: 'applications', params: { status: 'A' } }"
          title="Active applications"
          :count="getActiveAppCount"
          icon="media/svg/icons/Devices/Android.svg"
        ></count-stat-widget>
      </div>
      <div class="col-xl-4">
        <count-stat-widget
          :to="{ name: 'applications', params: { status: 'R' } }"
          title="Under review applications"
          :count="getUnderReviewAppCount"
          icon="media/svg/icons/Devices/Android.svg"
        ></count-stat-widget>
      </div>
      <div class="col-xl-4">
        <count-stat-widget
          :to="{ name: 'applications', params: { status: 'D' } }"
          title="Denied applications"
          :count="getDeniedAppCount"
          icon="media/svg/icons/Devices/Android.svg"
        ></count-stat-widget>
      </div>
      <div class="col-xl-4">
        <count-stat-widget
          to="/tutors"
          title="Tutors"
          :count="getTutorsCount"
          icon="media/svg/icons/General/Settings-1.svg"
        ></count-stat-widget>
      </div>
      <div class="col-xl-4">
        <count-stat-widget
          to="/students"
          title="Students"
          :count="getStudentsCount"
          icon="media/svg/icons/General/Settings-1.svg"
        ></count-stat-widget>
      </div>

      <div class="col-xl-4">
        <count-stat-widget
          to="/admins"
          title="Admins"
          :count="gt_adminsCount"
          icon="media/svg/icons/General/Settings-1.svg"
        ></count-stat-widget>
      </div>

      <!-- <div class="col-xxl-4">
        <explore-stat-widget
          frmLink="curriculum"
          lstLink="curriculums"
          title="Curriculums"
        ></explore-stat-widget>
      </div>
      <div class="col-xxl-4">
        <explore-stat-widget
          frmLink="category"
          lstLink="categories"
          title="Categories"
        ></explore-stat-widget>
      </div>
      <div class="col-xxl-4">
        <explore-stat-widget
          frmLink="subject"
          lstLink="subjects"
          title="Subjects"
        ></explore-stat-widget>
      </div> -->
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import ExploreStatWidget from "@/view/content/widgets/stats/ExploreStatWidget.vue";
import CountStatWidget from "@/view/content/widgets/stats/CountStatWidget.vue";

export default {
  name: "dashboard",
  components: {
    ExploreStatWidget,
    CountStatWidget,
  },
  computed: mapGetters("fire", [
    "getAvailableSessionsCount",
    "getCompletedSessionsCount",
    "getTotalSessionsCount",
    "getActiveAppCount",
    "getUnderReviewAppCount",
    "getDeniedAppCount",
    "getTutorsCount",
    "getStudentsCount",
    "gt_adminsCount",
  ]),
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
  },
  methods: {
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    },
  },
};
</script>
