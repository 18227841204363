<template>
  <div class="d-flex flex-column-fluid">
    <!--begin::Container-->
    <div class="container">
      <!--begin::Card-->
      <div class="card card-custom card-transparent">
        <div class="card-body p-0">
          <!--begin::Wizard-->
          <div
            class="wizard wizard-4"
            id="kt_wizard"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
          >
            <!--begin::Wizard Nav-->
            <div class="wizard-nav">
              <div class="wizard-steps">
                <div
                  class="wizard-step"
                  data-wizard-type="step"
                  data-wizard-state="current"
                >
                  <div class="wizard-wrapper">
                    <div class="wizard-number">
                      <i
                        style="color: white"
                        class="menu-icon flaticon2-shopping-cart-1"
                      ></i>
                    </div>
                    <div class="wizard-label">
                      <div class="wizard-title text-uppercase">LISTING</div>
                      <div class="wizard-desc">Sessions and packages</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--end::Wizard Nav-->
            <!--begin::Card-->
            <div
              class="card card-custom card-shadowless rounded-top-0 text-center"
              style="min-height: 72vh"
            >
              <div class="card-body p-0">
                <div class="px-8 py-lg-5 px-lg-10">
                  <div class="row">
                    <v-col cols="12">
                      <h1 class="mb-5">Sessions</h1>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="sessionPrice"
                        label="Session Price"
                        placeholder="Session price"
                        type="number"
                        min="0"
                        clearable
                      ></v-text-field>
                    </v-col>
                    <v-col cols="auto">
                      <b-button
                        variant="primary"
                        class="btn font-weight-bolder px-9 py-4"
                        :disabled="isDisabled"
                        @click="saveSessionPrice"
                      >
                        <i
                          style="color: #fff"
                          class="menu-icon flaticon2-check-mark"
                        ></i
                        >Save</b-button
                      >
                    </v-col>
                  </div>
                </div>
                <hr />
                <div class="px-8 px-lg-10">
                  <v-col cols="12">
                    <h1 class="mb-5">Packages</h1>
                  </v-col>
                  <div class="row justify-content-end">
                    <b-button
                      to="packagesAdd"
                      variant="primary"
                      class="btn font-weight-bolder px-9 py-4"
                    >
                      <i
                        style="color: #fff"
                        class="menu-icon flaticon2-add-1"
                      ></i
                      >Add Package</b-button
                    >
                  </div>
                  <div class="row justify-content-center">
                    <v-data-table
                      style="width: 100%; min-height: 100%"
                      class="font-weight-bolder row-pointer"
                      :headers="headers"
                      :items="getPackages"
                      disable-pagination
                      :hide-default-footer="true"
                      :search="search"
                      @click:row="editPackage"
                      dense
                    >
                      <template v-slot:top>
                        <v-text-field
                          v-model="search"
                          label="Search"
                          class="mx-4"
                        ></v-text-field>
                      </template>
                      <template v-slot:[`item.discount`]="{ item }">
                        {{ item.discount + "%" }}
                      </template>
                      <template v-slot:[`item.actions`]="{ item }">
                        <cmp-actions
                          :p_item="item"
                          :p_showEdit="false"
                          :p_showCopy="false"
                          @e_delete="deleteItem(item.id)"
                        />
                      </template>
                    </v-data-table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { sessionSettings } from "@/core/services/firebase.js";
import { mapGetters } from "vuex";
import { packagesCollection } from "../../../core/services/firebase";
import c_actions from "../../components/cmp-actions.vue";
export default {
  name: "sessionsPackages",
  components: { "cmp-actions": c_actions },
  data() {
    return {
      sessionPrice: 0,
      search: "",
      headers: [
        {
          text: "ID",
          value: "id",
        },
        {
          text: "Title",
          value: "title",
        },
        {
          text: "Number of sessions",
          value: "nrOfSessions",
        },
        {
          text: "Subtotal",
          value: "actualValue",
        },
        {
          text: "Discount",
          value: "discount",
        },
        {
          text: "Total",
          value: "price",
        },
        {
          text: "Actions",
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
      packages: [],
    };
  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Sessions And Packages" }]);
    let sesPrice = await sessionSettings.get();
    sesPrice = sesPrice.data();
    if (sesPrice.price != null) {
      this.sessionPrice = sesPrice.price;
    }
  },
  computed: {
    ...mapGetters("fire", ["getPackages"]),

    isDisabled() {
      return !(Number(this.sessionPrice) > 0);
    },
  },
  methods: {
    async deleteItem(itemId) {
      const result = await this.$swal({
        icon: "warning",
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn font-weight-bold btn-danger",
          cancelButton: "btn font-weight-bold btn-light",
        },
        showCancelButton: true,
        buttonsStyling: false,
      });
      if (result && result.value) {
        await packagesCollection.doc(itemId).delete();
        this.$swal.fire({
          icon: "success",
          title: "Deleted!",
          text: "Subject has been deleted.",
          customClass: {
            confirmButton: "btn font-weight-bold btn-light",
            buttonsStyling: false,
          },
        });
      }
    },
    async saveSessionPrice() {
      let payload = {};
      if (this.sessionPrice != null) {
        payload.price = Number(this.sessionPrice);
        await sessionSettings.set(payload, { merge: true });

        await this.$swal({
          icon: "success",
          title: "Success",
          text: `Session price has been updated`,
          buttonsStyling: false,
          confirmButtonText: "Ok",
          customClass: {
            confirmButton: "btn font-weight-bold btn-light",
          },
        });
      }
    },
    editPackage(item) {
      this.$router.push({
        name: "packagesAdd",
        params: {
          routerKey: item.id,
          otherProp: {
            title: item.title,
            discount: item.discount,
            nrOfSessions: item.nrOfSessions,
          },
        },
      });
    },
  },
};
</script>

<style scoped>
.btn-success {
  color: #fff !important;
  background-color: #28a745 !important;
  border-color: #28a745 !important;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>