import { firestoreAction } from "vuexfire";
import moment from "moment";
import {
  curriculumsCollection,
  studentsCollection,
  tutorsCollection,
  fb_adminsCollection,
  sessionsCollection,
  applicationsCollection,
  categoriesCollection,
  subjectsCollection,
  studentSessionsCollection,
  tutorSessionsCollection,
  bankTransferCollection,
  promoCodesCollection,
  packagesCollection,
  sessionSettings,
} from "../firebase.js";

const state = {
  curriculums: [],
  categories: [],
  subjects: [],
  tutors: [],
  st_admins: [],
  students: [],
  studentSessions: [],
  tutorSessions: [],
  education: [
    { value: "a", text: "All" },
    { value: "s", text: "School" },
    { value: "u", text: "University" },
    { value: "l", text: "Languages" },
    { value: "o", text: "Others" },
  ],
  selectedCuriculum: "",
  selectedCategory: "",
  selectedEducation: "",
  selectedSubjectEducation: "",
  selectedSubjectCategory: "",
  selectedTutor: "",
  selectedTutorStatus: "",
  selectedStudent: "",
  selectedStudentStatus: "",
  sessions: [],
  selectedSessionStatus: "",
  applications: [],
  selectedApplicationStatus: "",
  bankTransfers: [],
  promoCodes: [],
  reportYAxis: "",
  sessionSettings: [],
  packages: [],
};

const mutations = {
  setReportYAxis(state, payload) {
    state.reportYAxis = payload;
  },
  setSelectedCurriculum(state, payload) {
    state.selectedCuriculum = payload;
  },
  setSelectedSubjectEducation(state, payload) {
    state.selectedSubjectEducation = payload;
  },
  setSelectedSubjectCategory(state, payload) {
    state.selectedSubjectCategory = payload;
  },
  setEducationCategories(state, payload) {
    state.educationCategories = payload;
  },
  setSelectedEducation(state, payload) {
    state.selectedEducation = payload;
  },
  setSelectedCategory(state, payload) {
    state.selectedCategory = payload;
  },
  setSelectedTutor(state, payload) {
    state.selectedTutor = payload;
  },
  setSelectedTutorStatus(state, payload) {
    state.selectedTutorStatus = payload;
  },
  setSelectedStudent(state, payload) {
    state.selectedStudent = payload;
  },
  setSelectedStudentStatus(state, payload) {
    state.selectedStudentStatus = payload;
  },
  setSelectedSessionStatus(state, payload) {
    state.selectedSessionStatus = payload;
  },
  setSelectedApplicationStatus(state, payload) {
    state.selectedApplicationStatus = payload;
  },
};

const actions = {
  //TODO only bind when we open a page
  //unbind when we close?
  init: firestoreAction((context) => {
    context.bindFirestoreRef("curriculums", curriculumsCollection);
    context.bindFirestoreRef("st_admins", fb_adminsCollection);
    context.bindFirestoreRef("tutors", tutorsCollection);
    context.bindFirestoreRef("students", studentsCollection);
    context.bindFirestoreRef("sessions", sessionsCollection);
    context.bindFirestoreRef("applications", applicationsCollection);
    context.bindFirestoreRef("categories", categoriesCollection);
    context.bindFirestoreRef("subjects", subjectsCollection);
    context.bindFirestoreRef("studentSessions", studentSessionsCollection);
    context.bindFirestoreRef("tutorSessions", tutorSessionsCollection);
    context.bindFirestoreRef("bankTransfers", bankTransferCollection);
    context.bindFirestoreRef("promoCodes", promoCodesCollection);
    context.bindFirestoreRef("sessionSettings", sessionSettings);
    context.bindFirestoreRef("packages", packagesCollection);
  }),

  // unbindCategories: firestoreAction((unbindFirestoreRef) => {
  //     unbindFirestoreRef('categories')

  // }),

  getCategoriesByCurriculum: firestoreAction(
    ({ bindFirestoreRef, unbindFirestoreRef }, curriculum) => {
      bindFirestoreRef(
        "categories",
        curriculumsCollection.doc(curriculum).collection("categories")
      );
      //unbindFirestoreRef('categories')
    }
  ),

  getSubjectsByCurriculumAndCategory: firestoreAction(
    ({ bindFirestoreRef }, payload) => {
      bindFirestoreRef(
        "subjects",
        curriculumsCollection
          .doc(payload.curriculum)
          .collection("categories")
          .doc(payload.category)
          .collection("subjects")
      );
      //unbindFirestoreRef('categories')
    }
  ),
};

const getters = {
  getPackages: (state) => {
    return state.packages;
  },
  getSessionPrice: (state) => {
    return state.sessionSettings.price;
  },
  getPackageIdExists: (state) => (id) => {
    return state.packages.some(d => d.id === id);
  },
  getReportYAxis: (state) => {
    return state.reportYAxis;
  },
  getPromoCode: (state) => (promocode) => {
    return state.promoCodes.filter(function (d) {
      return d.id.toLowerCase() == promocode.toLowerCase();
    });
  },
  getPromoCodes: (state) => {
    return state.promoCodes;
  },
  getDailyRevenueByDate: (state) => (date) => {
    let sessions = state.sessions.filter(function (d) {
      let sessiondate = d.date.toDate();
      let reportdate = date;
      reportdate.setHours(0, 0, 0, 0);
      sessiondate.setHours(0, 0, 0, 0);
      return reportdate.valueOf() === sessiondate.valueOf();
    });
    return sessions.reduce((a, b) => {
      return a + b.price || 0
    }, 0);
  },
  getWeeklyRevenueByDate: (state) => (date) => {
    let sessions = state.sessions.filter(function (d) {
      let sessiondate = moment(d.date.toDate());
      let reportdate = moment(date);
      return (
        reportdate.week() === sessiondate.week() &&
        reportdate.year() === sessiondate.year()
      );
    });
    return sessions.reduce((a, b) => {
      return a + b.price || 0
    }, 0);
  },
  getMonthlyRevenueByDate: (state) => (date) => {
    let sessions = state.sessions.filter(function (d) {
      let sessiondate = moment(d.date.toDate());
      let reportdate = moment(date);
      return (
        reportdate.month() === sessiondate.month() &&
        reportdate.year() === sessiondate.year()
      );
    });
    return sessions.reduce((a, b) => {
      return a + b.price || 0
    }, 0);
  },
  getYearlyRevenueByDate: (state) => (date) => {
    let sessions = state.sessions.filter(function (d) {
      let sessiondate = moment(d.date.toDate());
      let reportdate = moment(date);
      return reportdate.year() === sessiondate.year();
    });
    return sessions.reduce((a, b) => {
      return a + b.price || 0
    }, 0);
  },
  getNrOfDailyDeliveredSessionsByDate: (state) => (date) => {
    return state.sessions.filter(function (d) {
      let sessiondate = d.date.toDate();
      let reportdate = date;
      reportdate.setHours(0, 0, 0, 0);
      sessiondate.setHours(0, 0, 0, 0);
      return reportdate.valueOf() === sessiondate.valueOf() && d.status == "CA";
    }).length;
  },
  getNrOfWeeklyDeliveredSessionsByDate: (state) => (date) => {
    return state.sessions.filter(function (d) {
      let sessiondate = moment(d.date.toDate());
      let reportdate = moment(date);
      return (
        reportdate.week() === sessiondate.week() &&
        reportdate.year() === sessiondate.year() &&
        d.status == "CA"
      );
    }).length;
  },
  getNrOfMonthlyDeliveredSessionsByDate: (state) => (date) => {
    return state.sessions.filter(function (d) {
      let sessiondate = moment(d.date.toDate());
      let reportdate = moment(date);
      return (
        reportdate.month() === sessiondate.month() &&
        reportdate.year() === sessiondate.year() &&
        d.status == "CA"
      );
    }).length;
  },
  getNrOfYearlyDeliveredSessionsByDate: (state) => (date) => {
    return state.sessions.filter(function (d) {
      let sessiondate = moment(d.date.toDate());
      let reportdate = moment(date);
      return reportdate.year() === sessiondate.year() && d.status == "CA";
    }).length;
  },
  getNrOfDailySessionsByDate: (state) => (date) => {
    return state.sessions.filter(function (d) {
      let sessiondate = d.date.toDate();
      let reportdate = date;
      reportdate.setHours(0, 0, 0, 0);
      sessiondate.setHours(0, 0, 0, 0);
      return reportdate.valueOf() === sessiondate.valueOf();
    }).length;
  },
  getNrOfWeeklySessionsByDate: (state) => (date) => {
    return state.sessions.filter(function (d) {
      let sessiondate = moment(d.date.toDate());
      let reportdate = moment(date);
      return (
        reportdate.week() === sessiondate.week() &&
        reportdate.year() === sessiondate.year()
      );
    }).length;
  },
  getNrOfMonthlySessionsByDate: (state) => (date) => {
    return state.sessions.filter(function (d) {
      let sessiondate = moment(d.date.toDate());
      let reportdate = moment(date);
      return (
        reportdate.month() === sessiondate.month() &&
        reportdate.year() === sessiondate.year()
      );
    }).length;
  },
  getNrOfYearlySessionsByDate: (state) => (date) => {
    return state.sessions.filter(function (d) {
      let sessiondate = moment(d.date.toDate());
      let reportdate = moment(date);
      return reportdate.year() === sessiondate.year();
    }).length;
  },
  getDisplayNameFromEmail: (state) => (email) => {
    const tutor = state.tutors.find((tutor) => tutor.account.email == email);
    if (tutor) {
      return tutor.account.displayName;
    }
    return "";
  },
  getAvailableSessionsCount(state) {
    return state.sessions.filter(function (e) {
      return e.status == "A";
    }).length;
  },
  getCompletedSessionsCount(state) {
    return state.sessions.filter(function (e) {
      return e.status == "C";
    }).length;
  },
  getTotalSessionsCount(state) {
    return state.sessions.length;
  },
  getActiveAppCount(state) {
    return state.applications.filter(function (e) {
      return e.status == "A";
    }).length;
  },
  getUnderReviewAppCount(state) {
    return state.applications.filter(function (e) {
      return e.status == "R";
    }).length;
  },
  getDeniedAppCount(state) {
    return state.applications.filter(function (e) {
      return e.status == "D";
    }).length;
  },
  getEducationCategories: (state) => (selectedEducation) => {
    if (state.selectedEducation == "a") return state.categories;
    return state.categories.filter(function (e) {
      return e.education == selectedEducation;
    });
  },
  getCategoriesForSubject: (state) => (selectedSubjectEducation) => {
    if (state.selectedSubjectEducation == "a") return state.categories;
    return state.categories.filter(function (e) {
      return e.education == selectedSubjectEducation;
    });
  },
  getSubjects: (state) => (selectedSubjectCategory, isCount) => {
    if (state.selectedSubjectEducation == "a" && !isCount)
      return state.subjects;
    return state.subjects.filter(function (e) {
      return e.category == selectedSubjectCategory;
    });
  },
  getSelectedCurriculum(state) {
    return state.selectedCuriculum;
  },
  getSelectedSubjectEducation(state) {
    return state.selectedSubjectEducation;
  },
  getSelectedSubjectCategory(state) {
    return state.selectedSubjectCategory;
  },
  getSelectedEducation(state) {
    return state.selectedEducation;
  },
  getSelectedCategory(state) {
    return state.selectedCategory;
  },
  getSelectedTutor(state) {
    return state.selectedTutor;
  },
  getTutorsByStatus(state) {
    if (state.selectedTutorStatus == "") return state.tutors;
    return state.tutors.filter(function (e) {
      return (
        e != null &&
        e.status != null &&
        e.status.account == state.selectedTutorStatus
      );
    });
  },
  getTutorById: (state) => (payload) => {
    return state.tutors.filter(function (e) {
      return e != null && e.account != null && e.account.email == payload;
    });
  },
  getStudentById: (state) => (payload) => {
    return state.students.filter(function (e) {
      return e != null && e.account != null && e.account.email == payload;
    });
  },
  getSelectedStudent(state) {
    return state.selectedStudent;
  },
  getStudentsByStatus(state) {
    if (state.selectedStudentStatus == "") return state.students;
    return state.students.filter(function (e) {
      return (
        e != null &&
        e.status != null &&
        e.status.account == state.selectedStudentStatus
      );
    });
  },
  getSelectedTutorStatus(state) {
    return state.selectedTutorStatus || "";
  },
  getSelectedStudentStatus(state) {
    return state.selectedStudentStatus || "";
  },
  getTutorsCount(state) {
    return state.tutors.length;
  },
  getStudentsCount(state) {
    return state.students.length;
  },
  gt_adminsCount(state) {
    return state.st_admins.length;
  },
  getSessionsByStatus(state) {
    if (state.selectedSessionStatus == "") return state.sessions;
    return state.sessions.filter((e) => {
      return (
        e != null && e.status != null && e.status == state.selectedSessionStatus
      );
    });
  },
  getSelectedSessionStatus(state) {
    return state.selectedSessionStatus || "";
  },
  getApplicationsByStatus(state) {
    if (state.selectedApplicationStatus == "") return state.applications;
    return state.applications.filter((e) => {
      return (
        e != null &&
        e.status != null &&
        e.status == state.selectedApplicationStatus
      );
    });
  },
  getSelectedApplicationStatus(state) {
    return state.selectedApplicationStatus || "";
  },
  getSubjectNameByID: (state) => (id) => {
    return state.subjects.filter((e) => {
      return (e != null && e.id == id);
    });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
