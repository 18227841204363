<template>
  <div class="d-flex flex-column-fluid">
    <!--begin::Container-->
    <div class="container">
      <!--begin::Card-->
      <div class="card card-custom card-transparent">
        <div class="card-body p-0">
          <!--begin::Wizard-->
          <div
            class="wizard wizard-4"
            id="kt_wizard"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
          >
            <!--begin::Wizard Nav-->
            <div class="wizard-nav">
              <div class="wizard-steps">
                <div
                  class="wizard-step"
                  data-wizard-type="step"
                  data-wizard-state="current"
                >
                  <div class="wizard-wrapper">
                    <div class="wizard-number">
                      <i
                        style="color: #fff"
                        class="menu-icon flaticon2-list-2"
                      ></i>
                    </div>
                    <div class="wizard-label">
                      <div class="wizard-title text-uppercase">LISTING</div>
                      <div class="wizard-desc">Curriculums</div>
                    </div>
                  </div>
                </div>
                <div class="wizard-step" data-wizard-type="step">
                  <div class="wizard-wrapper">
                    <div class="wizard-label">
                      <b-button
                        @click="m_onPublishClicked()"
                        variant="primary"
                        class="btn font-weight-bolder px-9 py-4"
                      >
                        <i
                          style="color: #fff"
                          class="menu-icon flaticon2-send-1"
                        ></i
                        >Publish</b-button
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--end::Wizard Nav-->

            <!--begin::Card-->
            <div
              class="card card-custom card-shadowless rounded-top-0 text-center"
              style="min-height: 72vh"
            >
              <!--begin::Body-->
              <div class="card-body p-0">
                <div
                  class="row justify-content-center py-8 px-8 py-lg-15 px-lg-10"
                >
                  <v-data-table
                    style="width: 100%; min-height: 100%"
                    class="font-weight-bolder"
                    :headers="headers"
                    :items="curriculums"
                    disable-pagination
                    :hide-default-footer="true"
                    :search="search"
                    :custom-filter="filterText"
                    dense
                  >
                    <template v-slot:top>
                      <v-text-field
                        v-model="search"
                        label="Search"
                        class="mx-4"
                      ></v-text-field>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                      <cmp-actions
                        :p_item="item"
                        @e_edit="editItem(item)"
                        @e_copy="copyItem(item)"
                        @e_delete="deleteItem(item.id)"
                      />
                    </template>

                    <!-- <template v-slot:[`item.actions`]="{item}">
                      <v-icon small class="mr-2" @click="editItem(item.id)"
                        >mdi-pencil</v-icon
                      >
                      <v-icon small @click="deleteItem(item.id)"
                        >mdi-delete</v-icon
                      >
                    </template> -->
                  </v-data-table>
                </div>
              </div>
              <!--end::Body-->
            </div>
            <!--end::Card-->
          </div>
          <!--end::Wizard-->
        </div>
      </div>
      <!--end::Card-->
    </div>
    <!--end::Container-->
  </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapState } from "vuex";
import {
  curriculumsCollection,
  dataCollection,
} from "@/core/services/firebase.js";

import { copyClipboard } from "@/core/utils/copyclipboard.js";
import { convertArrayToObject } from "@/core/utils/transform.js";
import c_actions from "../../components/cmp-actions.vue";
import { l_publishAll } from "../../../core/utils/lib-publish.js";

export default {
  name: "curriculum-lst",
  components: { "cmp-actions": c_actions },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Curriculums" }]);
  },
  data() {
    return {
      search: "",
      headers: [
        {
          text: "Key",
          value: "id",
        },
        {
          text: "EN",
          value: "en",
        },
        {
          text: "AR",
          value: "ar",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
        },
      ],
    };
  },
  computed: mapState("fire", ["curriculums"]),
  methods: {
    editItem(item) {
      this.$router.push({
        name: "curriculumEditForm",
        params: {
          routerKey: item.id,
          otherProp: {
            en: item.en,
            ar: item.ar,
          },
        },
      });
    },
    async deleteItem(id) {
      const result = await this.$swal({
        icon: "warning",
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn font-weight-bold btn-danger",
          cancelButton: "btn font-weight-bold btn-light",
        },
        showCancelButton: true,
        buttonsStyling: false,
      });
      if (result && result.value) {
        await curriculumsCollection.doc(id).delete();

        this.$swal.fire({
          icon: "success",
          title: "Deleted!",
          text: "Curriculum has been deleted.",
          customClass: {
            confirmButton: "btn font-weight-bold btn-light",
            buttonsStyling: false,
          },
        });
      }
    },
    filterText(value, search, item) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1
      );
    },

    copyItem(item) {
      copyClipboard(this.$swal, `${item.id} ${item.en} ${item.ar}`);
    },
    async m_onPublishClicked() {
      await l_publishAll(this);
    },
  },
};
</script>
