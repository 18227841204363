<template>
  <div class="d-flex flex-column-fluid">
    <!--begin::Container-->
    <div class="container">
      <!--begin::Card-->
      <div class="card card-custom card-transparent">
        <div class="card-body p-0">
          <!--begin::Wizard-->
          <div
            class="wizard wizard-4"
            id="kt_wizard"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
          >
            <!--begin::Wizard Nav-->
            <div class="wizard-nav">
              <div class="wizard-steps">
                <div
                  class="wizard-step"
                  data-wizard-type="step"
                  data-wizard-state="current"
                >
                  <div class="wizard-wrapper">
                    <div class="wizard-number">
                      <i
                        style="color: white"
                        class="menu-icon flaticon-users"
                      ></i>
                    </div>
                    <div class="wizard-label">
                      <div class="wizard-title text-uppercase">LISTING</div>
                      <div class="wizard-desc">Admins</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--end::Wizard Nav-->
            <!--begin::Card-->
            <div
              class="card card-custom card-shadowless rounded-top-0 text-center"
              style="min-height: 72vh"
            >
              <!--begin::Body-->

              <div class="card-body p-0">
                <div class="row py-8 px-8 py-lg-15 px-lg-10">
                  <div class="col-5">
                    <b-form-input
                      placeholder="Admin email"
                      type="email"
                      v-model="d_email"
                      class="form-control form-control-solid form-control-lg"
                    ></b-form-input>
                  </div>
                  <b-button
                    style="
                      padding: 0;
                      height: 45px;
                      width: 45px;
                      margin-right: 5px;
                    "
                    @click="m_toggleAdmin(false)"
                    variant="danger"
                  >
                    <b-icon-trash-fill></b-icon-trash-fill>
                  </b-button>
                  <b-button
                    style="padding: 0; height: 45px; width: 45px"
                    variant="primary"
                    @click="m_toggleAdmin(true)"
                  >
                    <b-icon-plus-circle-fill></b-icon-plus-circle-fill>
                  </b-button>

                  <v-data-table
                    style="width: 100%; min-height: 100%"
                    class="font-weight-bolder"
                    :headers="headers"
                    :items="st_admins"
                    disable-pagination
                    :hide-default-footer="true"
                    :search="search"
                    :custom-filter="m_filterText"
                    loading-text="Please wait"
                    dense
                  >
                    <template v-slot:top>
                      <v-text-field
                        v-model="search"
                        label="Search"
                        class="mx-4"
                      ></v-text-field>
                    </template>
                  </v-data-table>
                </div>
              </div>
              <!--end::Body-->
            </div>
            <!--end::Card-->
          </div>
          <!--end::Wizard-->
        </div>
      </div>
      <!--end::Card-->
    </div>
    <!--end::Container-->
  </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapState } from "vuex";
import { fb_setAdmin } from "../../../core/services/firebase";

export default {
  name: "admins-lst",

  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Admins" }]);
  },
  data() {
    return {
      d_email: "",
      search: "",
      headers: [
        {
          text: "Email",
          value: "id",
        },
        {
          text: "Display name",
          value: "account.displayName",
        },
        {
          text: "Phone number",
          value: "account.phone",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      st_admins: (st_state) => st_state.fire.st_admins,
    }),
  },
  methods: {
    async m_toggleAdmin(p_toggle) {
      if (!this.m_isValidEmail(this.d_email)) {
        return;
      }
      try {
        const l_response = await fb_setAdmin({
          email: this.d_email,
          toggle: p_toggle,
        });
        // console.log(l_response);
        if (!l_response || !l_response.data || !l_response.data.success) {
          this.$swal({
            icon: "error",
            title: "Error",
            text: `Something went wrong`,
            buttonsStyling: false,
            confirmButtonText: "Ok",
            customClass: {
              confirmButton: "btn font-weight-bold btn-light",
            },
          });
          return;
        }
        this.$swal({
          icon: "success",
          title: `${p_toggle ? "Added" : "Removed"}`,
          text: `Admin ${this.d_email} has been ${
            p_toggle ? "added" : "removed"
          }`,
          buttonsStyling: false,
          confirmButtonText: "Ok",
          customClass: {
            confirmButton: "btn font-weight-bold btn-light",
          },
        });
      } catch (p_error) {
        //console.error(p_error);
        this.$swal({
          icon: "error",
          title: "Error",
          text: `Something went wrong`,
          buttonsStyling: false,
          confirmButtonText: "Ok",
          customClass: {
            confirmButton: "btn font-weight-bold btn-light",
          },
        });
      }
    },
    m_isValidEmail(p_email) {
      return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        p_email
      );
    },
    m_filterText(value, search, item) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1
      );
    },
  },
};
</script>
