export function copyClipboard(swal, text) {
  _copyClipboard(text).then(() => {
    swal({
      position: "bottom-right",
      icon: "success",
      title: "Copied to Clipboard",
      showConfirmButton: false,
      timer: 1000,
    });
  });
}

function _copyClipboard(text) {
  return new Promise((resolve, reject) => {
    if (!navigator.clipboard) {
      return _legacyCopyClipboard(text);
    }
    navigator.clipboard.writeText(text).then(
      function () {
        resolve();
      },
      function () {
        reject();
      }
    );
  });
}

function _legacyCopyClipboard(text) {
  return new Promise((resolve, reject) => {
    var textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      document.execCommand("copy");
      document.body.removeChild(textArea);
      resolve();
    } catch (err) {
      document.body.removeChild(textArea);
      reject();
    }
  });
}
