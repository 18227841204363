<template>
  <div class="d-flex flex-column-fluid">
    <!--begin::Container-->
    <div class="container">
      <!--begin::Card-->
      <div class="card card-custom card-transparent">
        <div class="card-body p-0">
          <!--begin::Wizard-->
          <div
            class="wizard wizard-4"
            id="kt_wizard"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
          >
            <!--begin::Wizard Nav-->
            <div class="wizard-nav">
              <div class="wizard-steps">
                <div
                  class="wizard-step"
                  data-wizard-type="step"
                  data-wizard-state="current"
                >
                  <div class="wizard-wrapper">
                    <div class="wizard-number">
                      <i
                        style="color: white"
                        class="menu-icon flaticon-share"
                      ></i>
                    </div>
                    <div class="wizard-label">
                      <div class="wizard-title text-uppercase">LISTING</div>
                      <div class="wizard-desc">Sessions</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--end::Wizard Nav-->
            <!--begin::Card-->
            <div
              class="card card-custom card-shadowless rounded-top-0 text-center"
              style="min-height: 72vh"
            >
              <!--begin::Body-->

              <div class="card-body p-0">
                <div class="row py-8 px-8 py-lg-15 px-lg-10">
                  <div class="col-lg-6 col-xl-6">
                    <div class="col-lg-6 col-xl-6">
                      <span class="form-text text-muted text-left"
                        >Select session's status:</span
                      >
                      <b-form-select
                        class="form-control form-control-solid form-control-lg"
                        v-model="selectedStatus"
                        :options="statuses"
                        @change="onStatusChanged"
                      >
                      </b-form-select>
                    </div>
                  </div>
                  <v-data-table
                    style="width: 100%; min-height: 100%"
                    class="font-weight-bolder"
                    :headers="headers"
                    :items="getSessionsByStatus"
                    disable-pagination
                    :hide-default-footer="true"
                    :search="search"
                    :custom-filter="filterText"
                    :loading="tableLoading"
                    loading-text="Please wait"
                    dense
                  >
                    <template v-slot:top>
                      <v-text-field
                        v-model="search"
                        label="Search"
                        class="mx-4"
                      ></v-text-field>
                    </template>

                    <template v-slot:[`item.date`]="{ item }">
                      <span
                        class="label label-lg font-weight-bold label-inline date-label"
                        >{{ toDate(item.date) }}
                      </span>
                    </template>

                    <template v-slot:[`item.tutor.email`]="{ item }">
                      <b-button
                        id="show-btn"
                        variant="link"
                        @click="showPopulateDialog(item.sessionId, item.tutor)"
                        >{{ getTutorName(item.tutor) }}</b-button
                      >

                      <b-modal
                        :id="'changeTutorDialog' + item.sessionId"
                        hide-footer
                      >
                        <template #modal-title>
                          Change the tutor for {{ item.en }}
                        </template>
                        <div class="d-block text-center">
                          <h3>Change tutor</h3>
                        </div>
                        <div class="d-block text-center">
                          <b-form-select
                            v-model="selectedTutor"
                            :options="tutors"
                            value-field="account"
                            text-field="account.displayName"
                            :state="isTutorValid"
                          ></b-form-select>
                        </div>
                        <div class="d-block text-center">
                          <b-button
                            class="mt-3"
                            block
                            @click="changeTutor(item)"
                            :disabled="!isTutorValid"
                            >Save</b-button
                          >
                        </div>
                      </b-modal>
                    </template>

                    <template v-slot:[`item.status`]="{ item }">
                      <span
                        class="label label-lg font-weight-bold label-inline"
                        :class="
                          statuses[getStatusIndexByKey(item.status)].class
                        "
                        >{{ statuses[getStatusIndexByKey(item.status)].text }}
                      </span>
                    </template>
                  </v-data-table>
                </div>
              </div>
              <!--end::Body-->
            </div>
            <!--end::Card-->
          </div>
          <!--end::Wizard-->
        </div>
      </div>
      <!--end::Card-->
    </div>
    <!--end::Container-->
  </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters, mapState } from "vuex";
import {
  sessionsCollection,
  studentSessionsCollection,
  tutorSessionsCollection,
} from "@/core/services/firebase.js";
import { copyClipboard } from "@/core/utils/copyclipboard.js";
export default {
  name: "sessions-lst",
  components: {},
  computed: {
    ...mapGetters("fire", ["getSessionsByStatus"]),
    ...mapState("fire", ["tutors"]),
    isTutorValid() {
      return (
        this.selectedTutor != null && Object.keys(this.selectedTutor).length > 0
      );
    },
  },
  props: {
    status: { type: String },
  },
  async mounted() {
    let l_status = this.status || "";
    this.onStatusChanged(l_status);
    this.selectedStatus = this.$store.getters["fire/getSelectedSessionStatus"];
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Sessions" }]);
  },
  data() {
    return {
      selectedStatus: "",
      tableLoading: false,
      search: "",
      selectedTutor: {},
      headers: [
        {
          text: "Session Id",
          value: "id",
        },
        {
          text: "Student",
          value: "student",
        },
        {
          text: "Tutor",
          value: "tutor.email",
        },

        {
          text: "Price",
          value: "price",
        },
        {
          text: "Purchased At",
          value: "date",
        },
        {
          text: "Status",
          value: "status",
        },
      ],
      statuses: [
        {
          value: "",
          text: "All",
          class: "",
        },
        {
          value: "A",
          text: "Available",
          class: "label-light-success",
        },
        {
          value: "C",
          text: "Pending",
          class: "label-light-warning",
        },
        {
          value: "CA",
          text: "Completed",
          class: "label-light-primary",
        },
        {
          value: "CD",
          text: "Pending(Rejected)",
          class: "label-light-danger",
        },
      ],
    };
  },

  methods: {
    getTutorName(tutoremail) {
      return this.$store.getters["fire/getDisplayNameFromEmail"](tutoremail);
    },
    async changeTutor(session) {
      if (this.isTutorValid) {
        const payload = {
          tutor: this.selectedTutor.email,
        };

        sessionsCollection.doc(session.sessionId).set(payload, { merge: true });

        studentSessionsCollection
          .doc(session.student)
          .collection("sessions")
          .doc(session.sessionId)
          .set(payload, { merge: true });

        var tutorsession = tutorSessionsCollection
          .doc(session.tutor)
          .collection("sessions")
          .doc(session.sessionId);

        var tutorsessiondata = await tutorsession.get().then(function (doc) {
          return doc.data();
        });

        tutorsessiondata.tutor = payload.tutor;

        await tutorsession.delete();

        await tutorSessionsCollection
          .doc(this.selectedTutor.email)
          .collection("sessions")
          .doc(session.sessionId)
          .set(tutorsessiondata, { merge: true });

        this.$swal.fire({
          icon: "success",
          title: "Tutor updated!",
          text:
            "The " +
            session.en +
            " tutor for the user `" +
            session.student +
            "` is changed to " +
            this.selectedTutor.displayName +
            ".",
          customClass: {
            confirmButton: "btn font-weight-bold btn-light",
            buttonsStyling: false,
          },
        });
        this.$bvModal.hide("changeTutorDialog" + session.sessionId);
      }
    },
    showPopulateDialog(sessionId, tutorEmail) {
      const tutor = this.tutors.find((e) => {
        return e.account.email == tutorEmail;
      });
      this.selectedTutor = tutor.account;
      this.$bvModal.show("changeTutorDialog" + sessionId);
    },
    toDate(timestamp) {
      return this.$moment(timestamp.toDate()).format("DD-MM-YYYY");
    },
    getStatusIndexByKey(key) {
      for (let i = 0; i < this.statuses.length; i++) {
        if (this.statuses[i].value == key) return i;
      }
      return 0;
    },
    onStatusChanged(value) {
      this.$store.commit("fire/setSelectedSessionStatus", value);
    },

    filterText(value, search, item) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1
      );
    },
  },
};
</script>
