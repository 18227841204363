<template>
  <div class="d-flex flex-column-fluid">
    <!--begin::Container-->
    <div class="container">
      <!--begin::Card-->
      <div class="card card-custom card-transparent">
        <div class="card-body p-0">
          <!--begin::Wizard-->
          <div
            class="wizard wizard-4"
            id="kt_wizard"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
          >
            <!--begin::Wizard Nav-->
            <div class="wizard-nav">
              <div class="wizard-steps">
                <div
                  class="wizard-step"
                  data-wizard-type="step"
                  data-wizard-state="current"
                >
                  <div class="wizard-wrapper">
                    <div class="wizard-number">
                      <i
                        style="color: #fff"
                        class="menu-icon flaticon2-files-and-folders"
                      ></i>
                    </div>
                    <div class="wizard-label">
                      <div class="wizard-title text-uppercase">
                        {{ isEdit ? "EDIT" : "ADD" }}
                      </div>
                      <div class="wizard-desc">Package</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--end::Wizard Nav-->
            <!--begin::Card-->
            <div class="card card-custom card-shadowless rounded-top-0">
              <!--begin::Body-->
              <div class="card-body p-0">
                <div
                  class="row justify-content-center py-8 px-8 py-lg-15 px-lg-10"
                >
                  <div class="col-xl-12 col-xxl-10">
                    <!--begin::Wizard Form-->
                    <form class="form" id="kt_form">
                      <div class="row justify-content-center">
                        <div class="col-xl-9">
                          <!--begin::Wizard Step 1-->
                          <div
                            class="my-5 step"
                            data-wizard-type="step-content"
                            data-wizard-state="current"
                          >
                            <!--begin::Group-->
                            <div class="form-group row">
                              <label class="col-xl-3 col-lg-3 col-form-label"
                                >Package id</label
                              >
                              <div class="col-lg-9 col-xl-9">
                                <b-form-input
                                  v-model="id"
                                  :state="idState"
                                  placeholder="example: package10"
                                  class="
                                    form-control
                                    form-control-solid
                                    form-control-lg
                                  "
                                ></b-form-input>
                                <span class="form-text text-muted"
                                  >Package id</span
                                >
                              </div>
                            </div>
                            <!--end::Group-->
                            <!--begin::Group-->
                            <div class="form-group row">
                              <label class="col-xl-3 col-lg-3 col-form-label"
                                >Package title</label
                              >
                              <div class="col-lg-9 col-xl-9">
                                <b-form-input
                                  v-model="title"
                                  :state="titleState"
                                  placeholder="example: Package of 10"
                                  class="
                                    form-control
                                    form-control-solid
                                    form-control-lg
                                  "
                                ></b-form-input>
                                <span class="form-text text-muted"
                                  >Package title</span
                                >
                              </div>
                            </div>
                            <!--end::Group-->
                            <!--begin::Group-->
                            <div class="form-group row">
                              <label class="col-xl-3 col-lg-3 col-form-label"
                                >Nr of sessions</label
                              >
                              <div class="col-lg-9 col-xl-9">
                                <b-form-input
                                  v-model="nrOfSessions"
                                  :state="nrOfSessionsState"
                                  min="0"
                                  type="number"
                                  class="
                                    form-control
                                    form-control-solid
                                    form-control-lg
                                  "
                                ></b-form-input>
                                <span class="form-text text-muted"
                                  >Nr of sessions</span
                                >
                              </div>
                            </div>
                            <!--end::Group-->
                            <!--begin::Group-->
                            <div class="form-group row">
                              <label class="col-xl-3 col-lg-3 col-form-label"
                                >Discount(%)</label
                              >
                              <div class="col-lg-9 col-xl-9">
                                <b-form-input
                                  v-model="discount"
                                  :state="discountState"
                                  type="number"
                                  min="0"
                                  max="100"
                                  class="
                                    form-control
                                    form-control-solid
                                    form-control-lg
                                  "
                                ></b-form-input>
                                <span class="form-text text-muted"
                                  >Discount(%)</span
                                >
                              </div>
                            </div>
                            <!--end::Group-->
                            <!--begin::Group-->
                            <div class="form-group row">
                              <label class="col-xl-3 col-lg-3 col-form-label"
                                >Price</label
                              >
                              <div class="col-lg-9 col-xl-9">
                                <p>{{ price }}</p>
                              </div>
                            </div>
                            <!--end::Group-->
                          </div>
                          <!--end::Wizard Step 1-->

                          <!--begin::Wizard Actions-->
                          <div
                            class="
                              d-flex
                              justify-content-between
                              border-top
                              pt-10
                              mt-15
                            "
                          >
                            <div class="mr-2">
                              <c-back-button
                                title="Cancel"
                                inputClass="btn font-weight-bolder px-9 py-4"
                              ></c-back-button>
                              <!-- <b-button
                                @click="onCancelClicked()"
                                class="btn font-weight-bolder px-9 py-4"
                                >Cancel</b-button
                              > -->
                            </div>
                            <div>
                              <b-button
                                @click="onSaveClicked()"
                                variant="primary"
                                class="btn font-weight-bolder px-9 py-4"
                                >{{isEdit ? "Edit" : "Save"}}</b-button
                              >
                            </div>
                          </div>
                          <!--end::Wizard Actions-->
                        </div>
                      </div>
                    </form>
                    <!--end::Wizard Form-->
                  </div>
                </div>
              </div>
              <!--end::Body-->
            </div>
            <!--end::Card-->
          </div>
          <!--end::Wizard-->
        </div>
      </div>
      <!--end::Card-->
    </div>
    <!--end::Container-->
  </div>
</template>
<script>
const arabic = /[\u0600-\u06FF]/;
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { packagesCollection } from "@/core/services/firebase.js";
import CBackButton from "@/view/components/CBackButton.vue";
import { mapGetters, mapState } from "vuex";
export default {
  name: "package-frm",
  components: {
    CBackButton,
  },
  props: {
    routerKey: {
      type: String,
      default: "",
    },
    otherProp: {
      type: Object,
      default: null,
    },
  },

  mounted() {
    this.fillDataOnUpdate();
  },
  data: () => ({
    key: "",
    title: "",
    discount: 0,
    nrOfSessions: 0,
    id: "",
  }),
  computed: {
    ...mapState("fire", ["education"]),
    ...mapGetters("fire", ["getSessionPrice", "getPackageIdExists"]),
    actualValue() {
      return this.nrOfSessions * this.getSessionPrice;
    },
    price() {
      let actualValue = this.actualValue;
      return actualValue - (this.discount * actualValue) / 100;
    },
    idState() {
      return this.id.trim().length > 0 ? true : false;
    },
    titleState() {
      return this.title.trim().length > 0 ? true : false;
    },
    nrOfSessionsState() {
      return this.nrOfSessions > 0 ? true : false;
    },
    discountState() {
      return this.discount >= 0 && this.discount <= 100 ? true : false;
    },
    isIdExists() {
      return this.getPackageIdExists(this.id);
    },
    isEdit() {
        return this.isIdExists || this.routerKey.trim().length != 0;
    },
  },
  methods: {
    fillDataOnUpdate() {
      if (!this.isEdit) return;

      this.nrOfSessions = this.otherProp.nrOfSessions;
      this.discount = this.otherProp.discount;
      this.title = this.otherProp.title;
      this.id = this.routerKey;
    },
    async onSaveClicked() {
      if (
        !this.titleState ||
        !this.idState ||
        !this.discountState ||
        !this.nrOfSessionsState
      )
        return;

      const payload = {
        title: this.title,
        id: this.id,
        discount: Number(this.discount),
        nrOfSessions: Number(this.nrOfSessions),
        actualValue: this.actualValue,
        price: this.price,
        type: "p",
      };
      try {
        await packagesCollection.doc(this.id).set(payload, { merge: true });
      } catch (e) {
        // console.error(e);
      }
      await this.$swal({
        icon: "success",
        title: this.actionText(),
        text: `Package has been ${this.actionText().toLowerCase()}`,
        buttonsStyling: false,
        confirmButtonText: "Ok",
        customClass: {
          confirmButton: "btn font-weight-bold btn-light",
        },
      });

      if (!this.isEdit) this.clear();
      this.$router.go(-1)
    },
    actionText() {
      return this.isEdit ? "Updated" : "Added";
    },
    clear() {
      this.title = "";
      this.id = "";
      this.discount = 0;
      this.nrOfSessions = 0;
    },
  },
};
</script>
