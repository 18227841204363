var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-alert',{staticClass:"alert alert-custom alert-white alert-shadow fade show gutter-b",attrs:{"show":"","variant":"light"}},[_c('div',{staticClass:"alert-icon"},[_c('span',{staticClass:"svg-icon svg-icon-lg"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Tools/Compass.svg"}})],1)]),_c('div',{staticClass:"alert-text"},[_c('b',[_vm._v("Tooltips")]),_vm._v(" The "),_c('code',[_vm._v("v-tooltip")]),_vm._v(" component is useful for conveying information when a user hovers over an element. You can also programmatically control the display of tooltips through a "),_c('code',[_vm._v("v-model")]),_vm._v(". When activated, tooltips display a text label identifying an element, such as a description of its function. "),_c('a',{staticClass:"font-weight-bold",attrs:{"href":"https://vuetifyjs.com/en/components/tooltips","target":"_blank"}},[_vm._v(" See documentation. ")])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('KTCodePreview',{attrs:{"title":'Alignment'},scopedSlots:_vm._u([{key:"preview",fn:function(){return [_c('p',[_vm._v(" A tooltip can be aligned to any of the four sides of the activator element ")]),_c('div',{staticClass:"text-center"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-4",attrs:{"color":"primary","dark":""}},on),[_vm._v("Left")])]}}])},[_c('span',[_vm._v("Left tooltip")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-4",attrs:{"color":"primary","dark":""}},on),[_vm._v("Top")])]}}])},[_c('span',[_vm._v("Top tooltip")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-4",attrs:{"color":"primary","dark":""}},on),[_vm._v("Bottom")])]}}])},[_c('span',[_vm._v("Bottom tooltip")])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-4",attrs:{"color":"primary","dark":""}},on),[_vm._v("Right")])]}}])},[_c('span',[_vm._v("Right tooltip")])])],1)]},proxy:true},{key:"html",fn:function(){return [_vm._v(" "+_vm._s(_vm.code1.html)+" ")]},proxy:true}])})],1),_c('div',{staticClass:"col-md-6"},[_c('KTCodePreview',{attrs:{"title":'Visibility'},scopedSlots:_vm._u([{key:"preview",fn:function(){return [_c('p',[_vm._v(" Tooltip visibility can be programmatically changed using "),_c('code',[_vm._v("v-model")]),_vm._v(". ")]),_c('v-container',{staticClass:"text-center",attrs:{"fluid":""}},[_c('v-row',{staticClass:"flex",attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{on:{"click":function($event){_vm.show = !_vm.show}}},[_vm._v("toggle")])],1),_c('v-col',{staticClass:"mt-12",attrs:{"cols":"12"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',{attrs:{"color":"grey lighten-1"}},[_vm._v("mdi-cart")])],1)]}}]),model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('span',[_vm._v("Programmatic tooltip")])])],1)],1)],1)]},proxy:true},{key:"html",fn:function(){return [_vm._v(" "+_vm._s(_vm.code2.html)+" ")]},proxy:true},{key:"js",fn:function(){return [_vm._v(" "+_vm._s(_vm.code2.js)+" ")]},proxy:true}])})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }