<template>
  <b-button @click="onBackButtonClicked" :class="inputClass"
    >{{ title }}
  </b-button>
</template>
<script>
export default {
  name: "c-back-button",
  props: ["inputClass", "title"],
  methods: {
    onBackButtonClicked() {
      window.history.length > 2 ? this.$router.go(-1) : this.$router.push("/");
    },
  },
};
</script>
